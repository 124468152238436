import axios from "axios";
import {
    RCHANS_URL,
    RCHAN_KEYS_URL,
    buildServiceParamMap,
    mergeById,
    buildMapOfListsByAttribute,
    buildListOfValues,
    buildMapByValueName,
    buildMapOfListsByValueName,
    RRS_URL
} from "../../common";
import { userInfo } from "../../common/user-info"
import { authHeader } from "../../common/auth-header"
import { className } from "postcss-selector-parser";
import jwtInterceptor  from '../../common/jwt-interceptor';

const state = {
   channelsByRecipientUserId:{},
   channelsById:{},
   privateMessageRecipients: [],
   privateMessageRecipientsByChannelId: {},
   rallyChannels: [],
   privateChannels: {},
   newPrivateMessages:[],
   newPrivateMessageCountBySenderName:{}
};

const getters = {
  //rallyChannels: state => state.rallyChannel
  channelsByRecipientUserId: state => state.channelsByUserId,
  privateMessageRecipients: state => state.privateMessageRecipients,
  privateMessageRecipientsByChannelId: state => state.privateMessageRecipientsByChannelId,
  rallyChannels: state => state.rallyChannels,
  privateChannels: state => state.privateChannels,
  newPrivateMessages: state=>state.newPrivateMessages,
  newPrivateMessageCountBySenderName: state=>state.newPrivateMessageCountBySenderName
};



const actions = {
    //api/v1/channels?page%5Bnumber%5D=1&page%5Bsize%5D=100&channel_users=22812&type=PrivateMessage”
    

    async fetchUserChannels({commit, state}, inparams){
      const { userId } = inparams;
      let jsonparams = {
        channel_users: userId,
        type:"PrivateMessage",
        "page[number]": 1,
        "page[size]":100
      };
      let params = buildServiceParamMap(jsonparams);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        url: RCHANS_URL(),
        params
      };
      const response = await jwtInterceptor(requestOptions);
      let byIdMap = {}
      let byUserIdMap = {}
      let recipientsByChannelId = {}
      let recipients = []
      response.data.data.forEach(c => {
        if (c && c.attributes){
            byIdMap[c.id] = c
          byUserIdMap = buildMapOfListsByValueName(c.relationships['channel-users'].data, 'user-id', byUserIdMap)
          if (c.relationships['channel-users'].data && c.relationships['channel-users'].data.length > 0){
            recipients = [ ...recipients, ...c.relationships['channel-users'].data]
            //filter out my user id...it becomes problematic!!
            recipients = recipients.filter((r) => {
              return (parseInt(r['user-id']) !== parseInt(userId)) && r['user-id'];
            });
          }
        }
      });
      let userIdsByRallyId = buildMapOfListsByAttribute(response.data.data, "rally-id")

      commit("setChannelsByRecipientUserId", {byUserIdMap, recipients})
      commit("setChannelsById", {byIdMap})
      let userIds = Object.keys(byIdMap)
      if (userIds && userIds.length > 0){
        userIds = userIds.filter(function(s) {
        return s !== 'undefined'
        });
      }
      let rallyIds = Object.keys(userIdsByRallyId)
      if (rallyIds && rallyIds.length > 0){
        for (const rid of rallyIds) {
          let userIds = buildListOfValues("id", userIdsByRallyId[rid]);
          if (userIds && userIds.length > 0) {
             this.dispatch("memberships/fetchMembersByRallyAndUserId", { rallyId: rid, userIds });
          }
        }
      }

    },

    async addNewPrivateMessage({commit, state}, inparams){
      commit("setNewPrivateMessage", inparams);
    },

    async clearNewPrivateMessages({commit, state}, inparams){
      commit("clearNewPrivateMessages");
    },

    async clearPrivateMessageCountByUserName({commit, state}, inparams){
      commit("clearPrivateMessageCountByUserName", inparams);
    },

    async fetchChannelKey({commit, state}, inparams){
      const user = userInfo();
      const { channelId , channelKey} = inparams;
      var jsonparams = {
          channel_id: channelId,
          key: channelKey,
      };
      var params = buildServiceParamMap(jsonparams);
      const requestOptions = {
          method: "GET",
          headers: {
              "Content-Type": "application/json"
          },
          url: RCHAN_KEYS_URL(), //+ "/" + channelId + ".json",
          params
      };
      const response = await jwtInterceptor(requestOptions);
      //const channel_key = (rallyId + "").concat(':').concat(channelUsers[1]);

      //commit("setPrivateChannels", { channel_key , channels: [response.data.data]});
    },

    async fetchPrivateChannels({commit, state}, inparams){
      const user = userInfo();
      const { rallyId, channelUsers } = inparams;
      var jsonparams = {
          type: 'PrivateMessage',
          channel_users: channelUsers,
          rally_id: rallyId,
          sort: "sort_order",
          "page[number]": 1,
          "page[size]":100
      };
      var params = buildServiceParamMap(jsonparams);
      const requestOptions = {
          method: "GET",
          headers: {
              "Content-Type": "application/json"
          },
          url: RCHANS_URL(),
          params
      };
      const response = await jwtInterceptor(requestOptions);
      const channel_key = (rallyId + "").concat(':').concat(channelUsers[1]);

      commit("setPrivateChannels", { channel_key , channels: [response.data.data]});
    },

    async setChannelsById({commit, state}, inparams){
      commit("setChannelsById", inparams)
    },
    async updateChannel({ commit, state }, inparams) {
        const { channel } = inparams;
        const user = userInfo();
        const updatedAttributes = {
            id: channel.id,
        };
        const requestOptions = {
            method: "PUT",
            url: RCHANS_URL() + "/" + channel.id,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                data: {
                    type: "channels",
                    attributes: channel.attributes
                }
            }
        };
        const response = await jwtInterceptor(requestOptions);
    },

};
const mutations = {
    setChannelsByRecipientUserId: (state, params ) => {
      const { byUserIdMap, recipients} = params
        state.channelsByRecipientUserId = {...state.channelsByRecipientUserId, ...byUserIdMap}
        let pmr = mergeById(state.privateMessageRecipients, recipients, "user-id" )
        state.privateMessageRecipientsByChannelId = {...state.privateMessageRecipientsByChannelId, ...buildMapByValueName("channel-id", pmr)}
        state.privateMessageRecipients  = [...pmr]
    },
    setChannelsById: (state, params ) => {
      const { byIdMap} = params
        state.channelsById = {...state.channelsById, ...byIdMap}
    },
    setNewPrivateMessage: (state, params ) => {
      const { message } = params
        state.newPrivateMessages = [ message, ...state.newPrivateMessages]
        let senderName = message.title.split("from ")[1].split(":")[0]
        let senderCount = state.newPrivateMessageCountBySenderName[senderName]
        if (!senderCount){
          senderCount = 1
        } else {
          senderCount++
        }
        let newMap = {}
        newMap[senderName] = senderCount
        state.newPrivateMessageCountBySenderName = {...state.newPrivateMessageCountBySenderName, ...newMap}
    },
    clearNewPrivateMessages: (state, params ) => {
        state.newPrivateMessages = []
    },
    clearPrivateMessageCountByUserName: (state, params ) => {
      const { name } =  params
      let newMap = {} 
      newMap[name] = 0
      state.newPrivateMessageCountBySenderName = {...state.newPrivateMessageCountBySenderName, ...newMap}
    },

    setRallyChannels: (state, rChannels) =>
      (state.rallyChannels = rChannels),
    setPrivateChannels: (state, params) => {
        const {channel_key, channels} = params;
        var byChannelKeyMap = {};

        byChannelKeyMap[channel_key] = channels[0][0];

        state.privateChannels = { ...byChannelKeyMap};
    }
    //not used currently
    /*setRallyChannels: (state, rChannels) =>
      (state.rallyChannels = rChannels)*/
};
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};