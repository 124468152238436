import axios from "axios";
import jwtInterceptor  from '../../common/jwt-interceptor';

import {
  RMEMS_URL,
  RH_URL,
  buildServiceParamMap,
  updateMapById,
  buildListOfAttributeValues,
  performGet, sortByDate, RCS_URL, accessToken
} from "../../common";

import { userInfo } from "../../common/user-info";
import { authHeader } from "../../common/auth-header";
import { StatusBarStyle } from "@capacitor/core";

const state = {
  invitationsByMemberId: {},
  membersByRallyId: {},
  membersByUserIdByRallyId: {},
  membersByMemberId: {},
  memberSearchResults: {}, 
  membersByUserId: {},
  noSearchResultsFlag: false,
  membersMaxPageByRally: {}, 
  resultCountByRally: {},
  profileByMembershipId: {},
  myProfileByRallyId: {},
  myMembershipByRallyId: {},
  myCustomProfiles: [],
  myMemberships: []
};

const getters = {
  invitationsByMemberId: state => {
    return state.invitationsByMemberId;
  },
  membersByRallyId: state => {
    return state.membersByRallyId;
  },

  membersByUserIdByRallyId: state => {
    return state.membersByUserIdByRallyId
  },

  membersByMemberId: state => {
    return state.membersByMemberId;
  },

  membersByUserId: state => {
    return state.membersByUserId
  },

  memberSearchResults: state => {
    return state.memberSearchResults;
  },
  noSearchResultsFlag: state => {
    return state.noSearchResults
  },
  myProfileByRallyId: state => {
    return state.myProfileByRallyId
  },
  /*
  membersLoadedByRallyFlag: state=>{
    return state.membersLoadedByRallyFlag
  
  },*/
  membersMaxPageByRally: state=>{
    return state.membersMaxPageByRally
  },
  resultCountByRally: state=>{
    return state.resultCountByRally
  },
  profileByMembershipId: state=> {
    return state.profileByMembershipId
  },
  myMembershipByRallyId: state=> {
    return state.myMembershipByRallyId
  },
  myCustomProfiles: state=> {
    return state.myCustomProfiles
  }
};

const actions = {
  async clearMemberState({ commit, state }) {
    commit("clearState");
  },
  async fetchMembersByRally({ commit, state, getters, rootGetters}, inparams) {
    try{
    const user = userInfo();
    const { rallyId, status, fetchMyMemberInfo, role, customPageSize} = inparams;
    let pageNumber = 1
    let pnums = state.membersMaxPageByRally[rallyId]
      let statusParam = status;
    let roleParam = role;
    if (roleParam === undefined) {
      roleParam = ['Admin', 'Friend', 'Owner', 'Subscriber']
    }
    if (statusParam === undefined) {
      statusParam = ['Active', 'Invited', 'Requested', 'Affiliated']
    }
    if (pnums && !fetchMyMemberInfo){
      for (let i = 1; i < 25; i++){
        //find the first page number that hasn't been called yet...this prevents a bug where infinite scroll was skipping page nums
        if (!pnums[i]){
          pageNumber = i
          break
        }
      }
    }
      if(rallyId === null) {
        return;
      }
      let theStatus = status
      if (theStatus === null) {
        theStatus = 'Active'
      }

    console.log("customPageSize:" + (customPageSize ? customPageSize : 200))
    let jsonparams = {
      rally_id: rallyId,
      include: "user,membership_profile,avatar,invitation",
      user_id: fetchMyMemberInfo ? userInfo().id : "*",
      "status": statusParam,
      role: roleParam,
      include_admin_archived: true,
      "page[size]": customPageSize ? customPageSize : 200,
      "sort": "sort_index,last_name,first_name"
    };
    if (!fetchMyMemberInfo){
      jsonparams["page[number]"]= pageNumber
    }

    let response = await performGet(jsonparams, RMEMS_URL(), user);

    if ((!response.data.data || response.data.data.length === 0) && fetchMyMemberInfo){
      //rerun the search without the status...because the query fails with the the status if the user is not a member of this rally...
      //event if its a friendly status with a hub they ARE a member of
      jsonparams["status"] = null
      response = await performGet(jsonparams, RMEMS_URL(), user);
    }
    var userIds = buildListOfAttributeValues("user-id", response.data.data);
    this.dispatch("contents/setIncludedById", { included: response.data.included, avatarType: "user"});
    let usersById = rootGetters['user/usersById']
      commit("setInvitationsByMembershipId", { invitations: response?.data?.included?.filter(i => i.type === "invitations")})
    commit("setMembersByRallyId", { rallyId, members: response.data.data, pageNumber, usersById, loadRallyMap:!fetchMyMemberInfo, noPagination: fetchMyMemberInfo});
    if (!fetchMyMemberInfo){
      commit("setResultCountByRally", { rallyId,count: response.data.meta["total-count"] });
    } else {
     commit("setMyMembershipByRallyId", { rallyId,memberships: response.data.data });
    }
  }catch(e){
    console.log(e)
  }
  },

  //api/v1/memberships/3346113.json

  async deleteMembership({ commit, state }, inparams) {
    const { memberId, rallyId } = inparams;
    const user = userInfo();
    const requestOptions = {
      method: "DELETE",
      url: RMEMS_URL() + "/" + memberId + ".json",
      headers: {
        "Content-Type": "application/json"
      },
    };
    const response = await jwtInterceptor(requestOptions);
    commit("removeMembersByRallyId", { rallyId, membershipId: memberId});
  },

  async fetchMyMemberships({ commit, state, getters, rootGetters}, inparams) {
    try {
      const user = userInfo();
      let jsonparams = {
        include: "user,membership_profile,avatar",
        status: ['Active'],
        role: ['Owner', 'Admin', 'Friend'],
        "page[number]": 1,
        distinct: 'true',
        user_id: user.id,
        "page[size]": 500,
        "sort": "sort_index,last_name,first_name"
      };

      const response = await performGet(jsonparams, RMEMS_URL(), user);
      commit("setMyMemberships", { memberships: response.data.data });
    } catch(e){
      console.log(e)
    }
  },

  async fetchMembersByRallyAndUserId({ commit, state, getters, rootGetters}, inparams) {
    try{

    const user = userInfo();
    const { rallyId, rallyIds, status, userIds} = inparams;
    let pageNumber = 1
    //the "distinct: true" param is necessary for this call to return member info for a user different from your own
    let jsonparams = {
      include: "user,membership_profile,avatar",
       status ,
      "page[number]": 1,
      distinct: 'true',
      "page[size]": 20,
      "sort": "sort_index,last_name,first_name"
    };

      if (userIds != null) {
        jsonparams['user_id'] = userIds
      } else {
        jsonparams['user_id'] = user.id
      }
      if (rallyId != null) {
        jsonparams['rally_id'] = [rallyId]
      } else {
        jsonparams['rally_id'] = rallyIds
      }
    const response = await performGet(jsonparams, RMEMS_URL(), user);
    this.dispatch("contents/setIncludedById", { included: response.data.included, avatarType: "user"});
    let usersById = rootGetters['user/usersById']
    commit("setMembersByRallyId", { rallyId, members: response.data.data, pageNumber, usersById, loadRallyMap:true, noPagination:true });
  }catch(e){
    console.log(e)
  }
  },


//Started POST "/api/v1/memberships.json" for 127.0.0.1 at 2022-04-28 15:55:02 -0500
//Processing by Api::V1::MembershipsController#create as JSON
//Parameters: {"data"=>{"type"=>"memberships", "attributes"=>{"rally_id"=>"38618", "role"=>"Friend", "status"=>"Requested", "created_via"=>"RequestToJoin", "author_id"=>303137, 
//"sort_order"=>0, "user_id"=>303137, "first_name"=>"Jason", "last_name"=>"Topliff", "nickname"=>"", "email"=>"jason.topliff@rallyhood.com", "address"=>"", "city"=>"66086", 
//"state"=>"", "zip"=>"", "country"=>nil, "primary_phone"=>"", "position"=>"testing", "facebook_url"=>"", "linkedin_url"=>"", "twitter_url"=>"", "expertise"=>"", 
//"additional_info"=>"", "title"=>"Health Team", "rally_type"=>nil}}}

 
  //Started POST "/api/v1/memberships.json" for 127.0.0.1 at 2022-04-13 16:56:35 -0500
  //Processing by Api::V1::MembershipsController#create as JSON
  //Parameters: {"data"=>{"type"=>"memberships", "attributes"=>{"created_via"=>"RallyFriends", "author_id"=>303137, 
  //"invitation_text"=>"Hello Everyone,\r\n\r\nWe created...", "rally_id"=>"39019", "role"=>"Friend", "sort_order"=>0, "status"=>"Invited", "user_id"=>nil, 
  //"first_name"=>"Ernest", "last_name"=>"Worrell", "nickname"=>"", "email"=>"ernestpworrell@rallyhood.com", "address"=>nil, "city"=>nil, "state"=>nil, "zip"=>nil, 
  //"country"=>nil, "primary_phone"=>nil, "position"=>nil, "facebook_url"=>nil, "linkedin_url"=>nil, "twitter_url"=>nil, "expertise"=>nil, "additional_info"=>nil, 
  //"title"=>"JT August 18 Rally", "rally_type"=>nil}}}
  async createMemberInvite({ commit, state, getters, rootGetters }, inparams) {
    const { createdVia, invitationText, rallyId, role, status, firstName, lastName, nickname, email, title } = inparams;
    const user = userInfo();
    const createdAttributes = {
        created_via: createdVia,
        author_id: user.user_id,
        invitation_text: invitationText,
        rally_id: rallyId,
        role,
        sort_order: 0,
        status,
        first_name: firstName,
        last_name: lastName,
        nickname: nickname,
        email,
        title
    };
    const requestOptions = {
      method: "POST",
      url: RMEMS_URL(),
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "memberships",
          attributes: createdAttributes
        }
      }
    };
    const response = await jwtInterceptor(requestOptions);


    //temporarily populate name and email until user is loaded
    if (response.data.data){
      response.data.data.attributes["first-name"] =  firstName
      response.data.data.attributes["last-name"] =  lastName
      response.data.data.attributes["email"] =  email
      response.data.data.attributes["role"] = role
      //response.data.data.attributes["context-rally-id"] = rallyId
      //response.data.data.attributes["user-id"] = "memtmp" + Math.random() * 100000000000000000

    }

    commit("clearState");
    await this.dispatch("memberships/fetchMembersByRally", {rallyId: rallyId})
    let usersById = rootGetters['user/usersById']
    commit ("setMembersByRallyId", { rallyId, members: [response.data.data], loadRallyMap: true, pageNumber:0,  usersById})
    //commit("clearState");
  },

  async changeSort({ commit, state, getters, rootGetters }, inparams) {
    try {
      const { membershipIdOrdering } = inparams;
      const user = userInfo();
      let i = 0;
      membershipIdOrdering.forEach(rally => {
        rally.membership.attributes["sort-order"] = i 
        i++
      });
      this.dispatch("rallies/setMyRalliesUpdateSort", { "rallies": membershipIdOrdering});
      const createdAttributes = {
        sort: membershipIdOrdering.map(obj => obj.membership.id).join(",")
      };
      const requestOptions = {
        method: "PUT",
        url: RMEMS_URL() + "/update_sort_order",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          data: {
            type: "memberships",
            attributes: createdAttributes
          }
        }
      }
      const response =  await jwtInterceptor(requestOptions);
    } catch (e) {
      console.log(e)
    }
  },

  // DEPRECATED
  // This is hitting a Rallyhood Rails API endpoint that is no longer in use
  // The proper endpoint is in RMEMS_URL
  async inviteMember({ commit, state }, inparams) {
    const { member, rallyId, inviteAction } = inparams;
    const user = userInfo();
    let jsonparams = {
      rally_id: rallyId,
      do: inviteAction,
      channel_id: "directory"
    };
    //https://staging.rallyhood.com/43892/directory/memberships/2845789/do?do=kick
    const invite_url = RH_URL() + `${rallyId}/directory/memberships/${member.id}/do`;
      if (inviteAction === "denied" || inviteAction === "kick"){
        commit("removeMembersByRallyId", { rallyId, membershipId: member.id});
      } else if (inviteAction == "approve") {
        member.attributes.status = "Active"
        commit("updateMember", {member, rallyId})
      }
    const response = await performGet(jsonparams, invite_url, user);
  },

  async searchMembers({ commit, state, getters, rootGetters}, inparams) {
    const { status, query, rallyId, pageNumber, queryField } = inparams;
    const user = userInfo();
    commit("setNoSearchResultsFlag", {flag: false})
    let jsonparams = {
      include: "user,membership_profile,avatar",
      status: ["Active", "Invited", "Requested", "Affiliated"] ,
      "page[number]": !pageNumber ? "1" : pageNumber,
      "page[size]": 200,
      query,
      rally_id: rallyId,
      user_id: "*",
      query_field: queryField,
      "sort": "sort_index,last_name,first_name"
    };
    const query_url = RMEMS_URL() + `/search.json`;

    const response = await performGet(jsonparams, query_url, user);
    let userIds = buildListOfAttributeValues("user-id", response.data.data);
    this.dispatch("contents/setIncludedById", { included: response.data.included, avatarType: "user"});
    //this.dispatch("user/fetchUsersById", { userIds });
    let fl = response.data.data.filter(function(m) {
      return m.attributes.status !== "Denied" ;
    });
    let usersById = rootGetters['user/usersById']
    commit ("setMembersByRallyId", { rallyId, members: fl, pageNumber:0,  usersById, loadRallyMap:false, noPagination: true})
    commit("setMemberSearchResults", { members: fl });  
    if (!fl || fl.length == 0){
      commit("setNoSearchResultsFlag", {flag: true})
    }
  },

  async getMyCustomProfiles({ commit, state, getters, rootGetters}, inparams) {
    const { rallyId } = inparams;
    const user = userInfo();
    let jsonparams = {
      status: ["Active", "Affiliated"] ,
      "page[number]": 1,
      "page[size]": 200,
      is_custom: true,
      user_id: user.id,
      include: "user,membership_profile,avatar",
      "sort": "sort_index,title"
    };
    if (rallyId !== null) {
      jsonparams['rally_id'] = rallyId;
    }
    const query_url = RMEMS_URL();

    const response = await performGet(jsonparams, query_url, user);

    if (rallyId !== null && response.data.included) {
      const profiles = response.data.included.find(profile => profile.type === 'membership-profiles');
      commit("addMyCustomProfileByRallyId", { profile: profiles, rallyId: rallyId})
    } else {
      commit("setMyCustomProfiles", { memberships: response.data.data})
    }
  },

  async acceptInvite({ commit, state }, inparams) {
    const { member, rallyId } = inparams;
    const user = userInfo();

    const requestOptions = {
      method: "PUT",
      url: RMEMS_URL() + "/" + `${member.id}` + ".json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "memberships",
          attributes: { status: 'Active'}
        }
      }
    };
    member.attributes.status='Active'
    commit("updateMember", inparams)
    const response = await jwtInterceptor(requestOptions);
  },

  async acceptInviteForNewUser({ commit, state }, inparams) {
    const { member, rallyId, token, invitationToken } = inparams;

    const requestOptions = {
      method: "PUT",
      url: RMEMS_URL() + "/" + `${member.id}` + ".json",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data: {
        data: {
          type: "memberships",
          attributes: { status: 'Active',
                        rally_id: rallyId,
                        invitation_token: invitationToken,
                        first_name: member.attributes.first_name,
                        last_name: member.attributes.last_name,
                        timezone: member.attributes.timezone,
                        password: member.attributes.password
          }
        }
      }
    };

    const response = await jwtInterceptor(requestOptions);
  },

  async subscribeInvite({ commit, state }, inparams) {
    const { member, rallyId, token, invitationToken } = inparams;
       const requestOptions = {
      method: "PUT",
      url: RMEMS_URL() + "/" + `${member.id}` + ".json",
         headers: {
           "Content-Type": "application/json",
           "Authorization": `Bearer ${token}`
         },
      data: {
        data: {
          type: "memberships",
          attributes: {
                        invitation_token: invitationToken,
                        rally_id: rallyId,
                        role: 'Subscriber',
                        status: 'Active',}
        }
      }
    };
    member.attributes.status = 'Active'
    commit("updateMember", inparams)
    const response = await jwtInterceptor(requestOptions);
  },
  async rejectInvite({ commit, state }, inparams) {
    const { member, rallyId, token, invitationToken } = inparams;
    const requestOptions = {
      method: "PUT",
      url: RMEMS_URL() + "/" + `${member.id}` + ".json",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data: {
        data: {
          type: "memberships",
          attributes: { invitation_token: invitationToken,
            rally_id: rallyId,
            status: 'Declined'}
        }
      }
    };
    member.attributes.status = 'Declined'
    commit("updateMember", inparams)
    const response = await jwtInterceptor(requestOptions);
  },

  async resendInvitation({ commit, state }, inparams) {
    const { member, invitationText } = inparams;
    const user = userInfo();

    const requestOptions = {
      method: "POST",
      url: RMEMS_URL() + "/" + `${member.id}` + "/reinvite",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "memberships",
          rallyId: member.attributes.rallyId,
          invitationText: invitationText
        }
      }
    };

    const response = await jwtInterceptor(requestOptions);
  },

  async updateMemberRole({ commit, state }, inparams) {
    const { member, rallyId } = inparams;
    const user = userInfo();

    const requestOptions = {
      method: "PUT",
      url: RMEMS_URL() + "/" + `${member.id}` + ".json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "memberships",
          attributes: { role: member.attributes.role }
        }
      }
    };

    commit("updateMember", inparams)
    const response = await jwtInterceptor(requestOptions);
  },

  async updateMemberPrivacy({ commit, state }, inparams) {
    const { member, rallyId } = inparams;
    const user = userInfo();
    //const params = {"membership[privacy]" :  member.attributes.privacy}
    
    //const data = Object.keys(params)
    //  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    //  .join('&');
    const requestOptions = {
      method: "PUT",
      url: RMEMS_URL() + "/" + `${member.id}` + ".json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "memberships",
          attributes: {privacy: member.attributes.privacy, status: 'Active'} 
        }
      }
    };
    commit("updateMember", inparams)
    const response = await jwtInterceptor(requestOptions);
  },

  async updateMemberNotifications({ commit, state }, inparams) {
    const { member, rallyId } = inparams;
    const user = userInfo();
    //const params = {"membership[privacy]" :  member.attributes.privacy}

    //const data = Object.keys(params)
    //  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    //  .join('&');
    const requestOptions = {
      method: "PUT",
      url: RMEMS_URL() + "/" + `${member.id}` + ".json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "memberships",
          attributes: {disable_notifications: member.attributes['disable-notifications']}
        }
      }
    };
    commit("updateMember", inparams)
    const response = await jwtInterceptor(requestOptions);
  },

  async updateMemberProfile({ commit, state }, inparams) {
    const { member, profile } = inparams;
    const user = userInfo();

    const params = {
      is_custom: profile.attributes['is-custom'],
      additional_info: profile.attributes['additional-info'],
      address: profile.attributes['address'],
      address2: profile.attributes['address2'],
      address3: profile.attributes['address3'],
      birthday_month: profile.attributes['birthday-month'],
      city: profile.attributes['city'],
      country: profile.attributes['country'],
      expertise: profile.attributes['expertise'],
      facebook_url: profile.attributes['facebook-url'],
      first_name: profile.attributes['first-name'],
      last_name: profile.attributes['last-name'],
      linkedin_url: profile.attributes['linkedin-url'],
      position: profile.attributes['position'],
      primary_phone: profile.attributes['primary-phone'],
      state: profile.attributes['state'],
      twitter_url: profile.attributes['twitter-url'],
      zip: profile.attributes['zip']
    }
    const requestOptions = {
      method: "PUT",
      url: RMEMS_URL() + "/" + `${member.id}` + ".json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "memberships",
          attributes: params
        }
      }
    };
    commit("updateMember", inparams)
    commit("setProfileByMembershipId", inparams)
    const response = await jwtInterceptor(requestOptions);
  },

  async verifyInvitationToken({ commit, state }, inparams) {
    const { invitationToken, rallyId } = inparams;

    const requestOptions = {
      method: "GET",
      url: RMEMS_URL() + "/verify-invitation?" + `invitation_token=${invitationToken}&rally_id=${rallyId}`,
      headers: {
        "Content-Type": "application/json"
      },
    };
    const response = await jwtInterceptor(requestOptions);
    return response.data;
  },

  clearMemberSearchResults({ commit, state }, inparams) {
    commit("setMemberSearchResults", { members: [] });
    commit("setNoSearchResultsFlag", {flag: false})
  },
  setInvitationsByMembershipId({commit, state}, inparams) {
    commit("setInvitationsByMembershipId", inparams)
  },
  setProfileByMembershipId({commit, state}, inparams) {
    commit("setProfileByMembershipId", inparams)
  }
};

const mutations = {
  clearState: state => {

    state.membersByRallyId = {};
    state.membersMaxPageByRally = {};
    state.resultCountByRally = {};
  },
  updateMember: (state, params) => {
    const { rallyId, member } = params;
    let members = state.membersByRallyId[rallyId]
    if (!members){
      members = []
    }
    let fl = members.filter(function(m) {
      if (m.id === member.id) {
        m.attributes = member.attributes
      }
      return true
    });
    let byRally = {};
    let byUserMap = {};
    let midMap = {};
    byRally[rallyId] = fl
    midMap[member.id] = member;
    byUserMap[member.attributes["user-id"]] = member
    state.membersByRallyId = {...state.membersByRallyId, ...byRally}
    state.membersByMemberId = { ...state.membersByMemberId, ...midMap };
    state.membersByUserId = {...state.membersByUserId, ...byUserMap };
  },
  removeMembersByRallyId: (state, params) => {
    const { rallyId, membershipId } = params;
    let members = state.membersByRallyId[rallyId]
    let fl = members.filter(function(m) {
      return m.id !== membershipId;
    });
    let byRally = {};
    byRally[rallyId] = fl
    state.membersByRallyId = {...state.membersByRallyId, ...byRally}
  },
  setProfileByMembershipId: (state, params) => {
    const {profile} = params;
    let newMap = {};

    if (profile.relationships.membership){
      newMap[profile.relationships.membership.data.id] = profile
    } 
    else if (profile.relationships["membership-profile"]){
      newMap[profile.relationships["membership-profile"].data.id] = profile
    }

    state.profileByMembershipId = {...state.profileByMembershipId, ...newMap} 
  },
  setInvitationsByMembershipId: (state, params) => {
    const { invitations } = params;

    let newMap = {};
    if (invitations){
      for (let i of invitations) {
        newMap[i.relationships.membership.data.id] = i
      }
    }

    state.invitationsByMemberId = { ...state.invitationsByMemberId, ...newMap };
  },
  /*setMemberByMemberId: (state, params)=>{
    const { m } = params;
    let byUserMap = {};
    let midMap = {};
    midMap[m.id] = m;
    byUserMap[m.attributes["user-id"]] = m
    state.membersByMemberId = { ...state.membersByMemberId, ...midMap};
    state.membersByUserId = {...state.membersByUserId, ...byUserMap};

  },*/

  setMyMemberships: (state, params) => {
    const { memberships } = params;
    state.myMemberships = memberships
  },

  setMembersByRallyId: (state, params) => {
    const { rallyId, members, pageNumber, usersById, loadRallyMap, noPagination} = params;
    let byUserMap = {};
    let midMap = {};
    for (let m of members) {
      midMap[m.id] = m;
      byUserMap[m.attributes["user-id"]] = m
      let u = usersById[parseInt(m.attributes["user-id"])]
      if (u){
        m.attributes = {...u.attributes, ...m.attributes}
      } 
      let p = state.profileByMembershipId[m.id]
      m.attributes["last-name"] = p && p.attributes["last-name"] ? p.attributes["last-name"] : m.attributes["last-name"]
    }

    //don't load the by Rally map for individual user
    if (loadRallyMap){
       if (rallyId) {
        updateMapById(
          state,
          "membersByRallyId",
          "id",
          rallyId,
          members,
          ['status', 'role', 'last-name'], {'Admin':'2', 'Owner':'1'}
        )
       } else {
         members.forEach(m => {
          updateMapById(
            state,
            "membersByRallyId",
            "id",
            m.attributes["context-rally-id"],
            [m],
            ['status', 'role', 'last-name'], {'Admin':'2', 'Owner':'1'}
          )
         })
       }
    }

    state.membersByMemberId = { ...state.membersByMemberId, ...midMap};
    //this risky, the members will get overwritten based on which rally we are in
    state.membersByUserId = {...state.membersByUserId, ...byUserMap};
    if (rallyId){
      let memByUser = state.membersByUserIdByRallyId[rallyId] ? state.membersByUserIdByRallyId[rallyId] : {}
      memByUser= {...memByUser, ...byUserMap}
      let memByRid = {}
      memByRid[rallyId] = memByUser
      state.membersByUserIdByRallyId = {...state.membersByUserIdByRallyId, ...memByRid};
    }


    if (!noPagination){
      let newMap = {};
      let calledPages = state.membersMaxPageByRally[rallyId]
      if (!calledPages){
        calledPages = []
      }
      calledPages[pageNumber] = "called"
      newMap[rallyId]= calledPages
      state.membersMaxPageByRally = {...state.membersMaxPageByRally, ...newMap}
    }

  },

  setMyMembershipByRallyId: (state, params) =>{
    const {rallyId, memberships} = params;
    let newMap = {};
    newMap[rallyId]= memberships && memberships.length > 0 ? memberships[0] : null
    state.myMembershipByRallyId = {...state.myMembershipByRallyId, ...newMap}
  },

  setResultCountByRally: (state, params) =>{
    const {rallyId, count} = params;
    //console.log("setting result count to : " + "rally: " + rallyId + " count:" +  count)
    let newMap = {};
    newMap[rallyId]= count
    state.resultCountByRally = {...state.resultCountByRally, ...newMap}
  },

  setNoSearchResultsFlag: (state, params) => {
    const { flag } = params;
    state.noSearchResultsFlag = flag;
  },

  setMemberSearchResults: (state, params) => {
    const { members } = params;
    state.memberSearchResults = members;
  },

  setMyCustomProfiles: (state, params) => {
    const { memberships } = params;
    state.myCustomProfiles = [ ...memberships ]
    //state.myCustomProfiles = memberships;
  },
  addMyCustomProfileByRallyId: (state, params) => {
    const { profile, rallyId } = params;
    state.myProfileByRallyId[rallyId] = profile
    //let newMap = {};
    //newMap[rallyId]= profile
    //state.myProfileByRallyId[rallyId] = {...state.myProfileByRallyId, ...newMap}
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
