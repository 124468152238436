import Vue from 'vue';
import VeeValidate from 'vee-validate';

import { store } from './store';
import { router} from './common'; 
import { HB_LOGGING } from "./common";
//import {router} from './common/member-router';
import MyApp from './app/App';
import RHVueComponent from './app/RHVueComponent';
import VuePageTransition from 'vue-page-transition';
import { Plugins, Filesystem, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
import InfiniteLoading from 'vue-infinite-loading';
import FullCalendar from '@fullcalendar/vue'
import Popover from 'vue-js-popover'
import VueLogger from 'vuejs-logger';
import { loggerOptions } from "./common";
import { VueEditor } from "vue2-editor";
import ImageUploader from 'vue-image-upload-resize'
import { Cropper,	StencilPreview, BoundingBox, DraggableArea, } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import Datepicker from 'vuejs-datepicker';
import VueTextareaAutogrowDirective from 'vue-textarea-autogrow-directive'
import VueTimepicker from 'vue2-timepicker';
import * as VueGoogleMaps from 'vue2-google-maps'
import draggable from 'vuedraggable'

//custom components
import ActivityFeedContent from './components/ActivityFeedContent'
import ActivityFeedUserAvatar from './components/ActivityFeedUserAvatar'
import AttachedFile from './components/AttachedFile'
import ContentAttachments from './components/ContentAttachments'
import AvatarUpload from './components/AvatarUpload'
import ContentParticipations from './components/ContentParticipations'
import ManageAttendees from './components/ManageAttendees'
import PayableParticipations from './components/PayableParticipations'
import DashboardNav from "./components/DashboardNav";
import DetailsIcon from "./components/DetailsIcon";
import DirectoryMember from './components/DirectoryMember'
import EmptyState from './components/EmptyState'
import GiveParticipation from './components/GiveParticipation'
import MembersMember from './components/MembersMember'
import MembersComponent from './components/MembersComponent'
import OpenRhWeb from './components/OpenRhWeb'
import OpenRhWebSpecialEvents from './components/OpenRhWebSpecialEvents'
import OpenRhWebPayments from "./components/OpenRhWebPayments.vue";
import OpenRhWebGives from "./components/OpenRhWebGives.vue";
import PayParticipation from './components/PayParticipation'
import ShareIcon from "./components/ShareIcon";
import UnshareIcon from "./components/UnshareIcon";
import DeleteButton from "./components/DeleteButton";
import DeleteIcon from "./components/DeleteIcon";
import ContentElipsisMenu from "./components/ContentElipsisMenu";
import MemberElipsisMenu from "./components/MemberElipsisMenu";
import ContentPin from "./components/ContentPin";
import PayableElipsisMenu from "./components/PayableElipsisMenu";
import DirectMessageModal from "./components/DirectMessageModal";
import ToolBar from "./components/ToolBar";
import UserAvatar from './components/UserAvatar'
import UserMenu from './components/UserMenu'
import Comments from './components/Comments'
import PromoteIcon from "./components/PromoteIcon";
import RhNotification from './components/RhNotification'
import RallyNav from "./components/RallyNav";
import Sentiments from './components/Sentiments'
import SignupParticipation from './components/SignupParticipation'
import AdminSignupParticipation from './components/AdminSignupParticipation'
import StandardContent from './components/StandardContent'
import Rally from './components/Rally'
import StripeAccount from './components/StripeAccount'
import PayableEventParticipation from './components/PayableEventParticipation'
import SimplePayableEventParticipation from './components/SimplePayableEventParticipation.vue'
import EventParticipation from './components/EventParticipation'
import DarkOverlay from './components/DarkOverlay'
import Viewer from 'v-viewer'
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import VueGtag from "vue-gtag";
import HoneybadgerVue from '@honeybadger-io/vue';


// Content-specific Componenets (new naming convention)
import AlbumDetails from './components/content/album/Details'
import AlbumForm from './components/content/album/Form'
import AlbumListed from './components/content/album/Listed'
import BoardDetails from './components/content/board/Details'
import BoardForm from './components/content/board/Form'
import BoardListed from './components/content/board/Listed'
import PrivateMessageForm from './components/content/private_message/Form'
import PrivateMessageListed from './components/content/private_message/Listed'
import EventActivityFeedListed from './components/content/event/ActivityFeedListed'
import EventForm from './components/content/event/Form'
import EventDetails from './components/content/event/Details'
import FileActivityFeedListed from './components/content/file/ActivityFeedListed'
import FileForm from './components/content/file/Form'
import FileListed from './components/content/file/Listed'
import FileDetails from './components/content/file/Details'
import FolderDetails from './components/content/folder/Details'
import FolderForm from './components/content/folder/Form'
import FolderListed from './components/content/folder/Listed'
import FolderMoveModal from './components/content/folder/MoveModal'
import GivePayableGroupDetails from './components/content/give_payable_group/Details'
import GivePayableGroupListed from './components/content/give_payable_group/Listed'
import LinkActivityFeedListed from './components/content/link/ActivityFeedListed'
import LinkListed from './components/content/link/Listed'
import LinkForm from './components/content/link/Form'
import PayableEventActivityFeedListed from './components/content/payable_event/ActivityFeedListed'
import PayableEventDetails from './components/content/payable_event/Details'
import PayableEventListed from './components/content/payable_event/Listed'
import PayableGroupDetails from './components/content/payable_group/Details'
import PayableGroupListed from './components/content/payable_group/Listed'
import PhotoLightbox from './components/content/photo/Lightbox'
import PhotoListed from './components/content/photo/Listed'
import SignupActivityFeedListed from './components/content/signup/ActivityFeedListed'
import SignupEventDetails from './components/content/signup/Details'
import SignupEventForm from './components/content/signup/Form'
import SignupEventListed from './components/content/signup/Listed'
import SignupListDetails from './components/content/signup_list/Details'
import SignupListForm from './components/content/signup_list/Form'
import SignupListListed from './components/content/signup_list/Listed'
import WallMessageForm from './components/content/wall_message/Form'
import WallMessageActivityFeedListed from './components/content/wall_message/ActivityFeedListed'
import WallMessageListed from './components/content/wall_message/Listed'
import WallMessageShared from './components/content/wall_message/Shared'
//import PayPal from 'vue-paypal-checkout'

// --- RALLY SETTINGS SPECIFIC COMPONENTS
import RallyOptions from "./components/rally_settings/RallyOptions.vue";
import ManageFeatures from './components/rally_settings/ManageFeatures.vue'
import UserRallyPreferences from "./components/rally_settings/UserRallyPreferences.vue";
import ManagePayments from "./components/rally_settings/ManagePayments.vue";
import RallyProfile from "./components/rally_settings/RallyProfile.vue";

// --- USER SETTINGS SPECIFIC COMPONENTS
import ChangePassword from "./components/user_settings/ChangePassword.vue";
import DigestSettings from "./components/user_settings/DigestSettings.vue";
import EmailAddresses from "./components/user_settings/EmailAddresses.vue";
import ManageAccount from "./components/user_settings/ManageAccount.vue";
import NotificationSettings from "./components/user_settings/NotificationSettings.vue";
import UserAvatarUpload from "./components/user_settings/UserAvatarUpload.vue";
import UserProfile from "./components/user_settings/UserProfile.vue";


const { App } = Plugins;

Vue.use(VueLogger, loggerOptions);
Vue.use(Popover)
Vue.use(VuePageTransition);
Vue.use(VeeValidate);
Vue.use(InfiniteLoading, { /* options */ });
Vue.use(Viewer);
Vue.use(VueTextareaAutogrowDirective)
Vue.component('VueEditor', VueEditor);
Vue.component('StripeElementCard', StripeElementCard);
Vue.use(VueGtag, {
  config: { id: "G-SHENELS5D0" }
});

const config = { 
  debug: true,
  apiKey: 'hbp_mlXot9IPVWwq3vPFQKHBAYzkMH9wla0NUGiX',
  environment: 'production',
  revision: '6.6.21'
}
if (HB_LOGGING()){
  HoneybadgerVue.install(Vue, config)
}
Vue.use(ImageUploader);
Vue.use(Cropper);

//register all global components here
Vue.component('FullCalendar', FullCalendar);
//Vue.component('paypal-checkout', PayPal)
Vue.component('Datepicker', Datepicker)
Vue.component('VueTimepicker', VueTimepicker)
Vue.component('draggable', draggable)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBgSQKpOqISzg2WkCZ06UqmWO48NERQeP8', 
    libraries: 'places', 
    installComponents: true
    // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  }
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:

});




//custom components
Vue.component('ActivityFeedContent', ActivityFeedContent);
Vue.component('ActivityFeedUserAvatar', ActivityFeedUserAvatar);
Vue.component('AttachedFile', AttachedFile);
Vue.component('ContentAttachments', ContentAttachments);
Vue.component('AvatarUpload', AvatarUpload);
Vue.component('ContentParticipations', ContentParticipations);
Vue.component('ManageAttendees', ManageAttendees);
Vue.component('PayableParticipations', PayableParticipations);
Vue.component('DashboardNav', DashboardNav);
Vue.component('DetailsIcon', DetailsIcon);
Vue.component('DirectoryMember', DirectoryMember);
Vue.component('EmptyState', EmptyState);
Vue.component('GiveParticipation', GiveParticipation);
Vue.component('MembersMember', MembersMember);
Vue.component('MembersComponent', MembersComponent)
Vue.component('OpenRhWeb', OpenRhWeb)
Vue.component('OpenRhWebSpecialEvents', OpenRhWebSpecialEvents)
Vue.component('OpenRhWebPayments', OpenRhWebPayments)
Vue.component('OpenRhWebGives', OpenRhWebGives)
Vue.component('ShareIcon', ShareIcon);
Vue.component('UnshareIcon', UnshareIcon);
Vue.component('DeleteButton', DeleteButton);
Vue.component('DeleteIcon', DeleteIcon);
Vue.component('ContentElipsisMenu', ContentElipsisMenu);
Vue.component('MemberElipsisMenu', MemberElipsisMenu);
Vue.component('ContentPin', ContentPin);
Vue.component('PayableElipsisMenu', PayableElipsisMenu);
Vue.component('DirectMessageModal', DirectMessageModal);
Vue.component('RhNotification', RhNotification);
Vue.component('ToolBar', ToolBar);
Vue.component('UserAvatar', UserAvatar);
Vue.component('UserMenu', UserMenu);
Vue.component('Comments', Comments);
Vue.component('PayParticipation', PayParticipation);
Vue.component('PromoteIcon', PromoteIcon);
Vue.component('RallyNav', RallyNav);
Vue.component('Sentiments', Sentiments);
Vue.component('SignupParticipation', SignupParticipation);
Vue.component('AdminSignupParticipation', AdminSignupParticipation);
Vue.component('StandardContent', StandardContent);
Vue.component('PayableEventParticipation', PayableEventParticipation);
Vue.component('SimplePayableEventParticipation', SimplePayableEventParticipation);
Vue.component('EventParticipation', EventParticipation);
Vue.component('DarkOverlay', DarkOverlay);
Vue.component('Rally', Rally);
Vue.component('StripeAccount', StripeAccount)

// Content-specific Componenets (new naming convention)
Vue.component('AlbumDetails', AlbumDetails);
Vue.component('AlbumForm', AlbumForm);
Vue.component('AlbumListed', AlbumListed);
Vue.component('BoardDetails', BoardDetails);
Vue.component('BoardForm', BoardForm);
Vue.component('BoardListed', BoardListed);
Vue.component('PrivateMessageForm', PrivateMessageForm);
Vue.component('PrivateMessageListed', PrivateMessageListed);
Vue.component('EventActivityFeedListed', EventActivityFeedListed);
Vue.component('EventForm', EventForm);
Vue.component('EventDetails', EventDetails);
Vue.component('FileActivityFeedListed', FileActivityFeedListed);
Vue.component('FileForm', FileForm);
Vue.component('FileListed', FileListed);
Vue.component('FileDetails', FileDetails);
Vue.component('FolderDetails', FolderDetails);
Vue.component('FolderForm', FolderForm);
Vue.component('FolderListed', FolderListed);
Vue.component('FolderMoveModal', FolderMoveModal)
Vue.component('GivePayableGroupDetails', GivePayableGroupDetails);
Vue.component('GivePayableGroupListed', GivePayableGroupListed);
Vue.component('LinkActivityFeedListed', LinkActivityFeedListed);
Vue.component('LinkListed', LinkListed);
Vue.component('LinkForm', LinkForm);
Vue.component('PayableEventActivityFeedListed', PayableEventActivityFeedListed);
Vue.component('PayableEventDetails', PayableEventDetails);
Vue.component('PayableEventListed', PayableEventListed);
Vue.component('PayableGroupDetails', PayableGroupDetails);
Vue.component('PayableGroupListed', PayableGroupListed);
Vue.component('PhotoLightbox', PhotoLightbox);
Vue.component('PhotoListed', PhotoListed);
Vue.component('SignupActivityFeedListed', SignupActivityFeedListed);
Vue.component('SignupEventDetails', SignupEventDetails);
Vue.component('SignupEventForm', SignupEventForm);
Vue.component('SignupEventListed', SignupEventListed);
Vue.component('SignupListDetails', SignupListDetails);
Vue.component('SignupListForm', SignupListForm);
Vue.component('SignupListListed', SignupListListed);
Vue.component('WallMessageDetails', WallMessageListed);
Vue.component('WallMessageForm', WallMessageForm);
Vue.component('WallMessageActivityFeedListed', WallMessageActivityFeedListed);
Vue.component('WallMessageListed', WallMessageListed);
Vue.component('WallMessageShared', WallMessageShared);

// --- RALLY SETTINGS COMPONENTS
Vue.component('RallyOptions', RallyOptions)
Vue.component('ManageFeatures', ManageFeatures);
Vue.component('UserRallyPreferences', UserRallyPreferences)
Vue.component('ManagePayments', ManagePayments)
Vue.component('RallyProfile', RallyProfile)

// --- USER SETTINGS COMPONENTS
Vue.component('ChangePassword', ChangePassword)
Vue.component('DigestSettings', DigestSettings);
Vue.component('EmailAddresses', EmailAddresses)
Vue.component('ManageAccount', ManageAccount)
Vue.component('NotificationSettings', NotificationSettings)
Vue.component('UserAvatarUpload', UserAvatarUpload)
Vue.component('UserProfile', UserProfile)

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          try {
            vnode.context[binding.expression](event);
          } catch (e){
            console.log(e)
          }
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  });
  //updatethis

  export const eventBus = new Vue();
  export const globalVueRef = eventBus;
  
  new Vue({
    el: '#app',
    data: {
      paypal: {
        sandbox: 'AebrtfvrdVtPgkOWyyUxB4Y_57X5p1Dqa8tfFXbsnIjjHIo8pKSC0SZD4jwODbq6nnoRokSFfUv6Gsbn',
        production: 'donthavethisyet'
      }
    },
    router,
    store,
    render: h => h(MyApp)
});

/*
new Vue({
    el: '#app',
    data: {
    },
    router,
    store,
    render: h => h(RHVueComponent)
});*/