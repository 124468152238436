<template>
  <div v-if="true">
    <DashboardNav />
    <main :class="['messaging', {'subchannels-open': subchannelsState}]">
      <ToolBar
        v-on:perform="perform"
        :subchannels="privateMessageRecipients"
        :options="toolbarOptions"
      />
      <SubchannelsMenu
        v-on:perform="perform"
        :subchannels="privateMessageRecipients"
        :active="activeSubchannelId"
      />

      <div  v-if="recipientUser && recipientUser.attributes" class="who-can-see-this">
        <div class="question">Who can see this conversation?</div>
        Only you and {{ recipientUser.attributes['first-name'] }}  {{ recipientUser.attributes['last-name'] }} 
      </div> 
      <section class="content-area" v-if="activeSubchannelId > 0">

        <StandardContent
          v-for="content in selectedContents"
          v-on:perform="perform"
          :ref="`${content.id}`"
          :channelId="content.privateMessageChannel.id"
          :channel="content.privateMessageChannel"
          :content="content"
          :key="content.id"
          :contentId="content.id"
          :rally="{id: -1}"
          :rallyId="content.privateMessageChannel.attributes['rally-id']"
          :pUserId ="recipientUser.id"
          :observer="observer"
        />

         <div v-if="recipientUser && lastMessage" style="margin-bottom: 50px;">
         <textarea
            class="dmreplybox"
            v-model="dmText"
            rows="3"
            placeholder="Write a message..."
            style="width: 95%; margin-left: 7px; position: relative; z-index: 3; height: 1px; "
          ></textarea>
           <i class="fas fa-arrow-up" aria-hidden="true"
           name="send" @click.prevent="sendDirectMessage(lastMessageRallyId, recipientUser.id, lastMessageChannelId)"
           style="position: relative; z-index: 10; top: -40px; opacity: 50%; display: inline-block; min-width: 30px; min-height: 30px; float: right; margin-right: 30px; color: white; background-color: #36a8ae; border: 1px solid transparent; border-radius: 2rem; padding-top: 5px;
    padding-left: 6px;">
           </i>
        </div>
        <div>
          <br/>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from "vuex"
  import { userInfo } from "../common/user-info";
  import { compareBy } from "../common";
  import SubchannelsMenu from "../components/SubchannelsMenu.vue"
  import { eventBus } from "../main.js";
  export default {
    components: {
      SubchannelsMenu
    },
    data() {
      return {
        activeSubchannelId: 1,
        subchannelsState: true,
        loadAttempted: false,
        observer: null,
        dmText: "",
        subchannelSelected: false
      };
    },
    computed: {
      ...mapState("channels", ["channelsById", "channelsByRecipientUserId", "privateMessageRecipients", "privateMessageRecipientsByChannelId", "channelsByRecipientUserId"]),    
      ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
      ...mapState("user", ["status", "user", "usersById"]),
      selectedContents(){
        let cs = [] 
        this.selectedChannels?.forEach(c => {
            if (this.allContentByContextTypeAndId[c]){
              cs = [...cs, ...this.allContentByContextTypeAndId[c]]
            }
        });
        
        return  cs && cs.sort ? cs.sort(compareBy({}, "created-at")) : cs
      },
      lastMessage(){
        if (this.selectedContents && this.selectedContents.length > 0){
          return this.selectedContents[this.selectedContents.length -1]
        } else {
          return null
        }
      },
      lastMessageChannelId(){
        return  this.lastMessage.privateMessageChannel.id
      },
      lastMessageRallyId(){
        return this.lastMessage.privateMessageChannel.attributes['rally-id']
      },
      selectedChannels(){
        var cl = []
        this.channelsByRecipientUserId[this.activeSubchannelId]?.forEach(c => {
          cl.push(c["channel-id"])
        })
        return cl
      },
      isMobile() {
        return document.body.scrollWidth <= 769;
      },
      recipientUser(){
        return this.activeSubchannelId ? this.usersById[this.activeSubchannelId] : null
      },
      toolbarOptions() {
        let options = new Array();

        return options;
      },
      checkReload(){
        if (!this.loadAttempted){
          this.loadPageData()
          return false
        } else if (!this.privateMessageRecipients || this.privateMessageRecipients.length == 0) {
          return true
        } else {
          return true
        }
      }
    },
    async mounted() {
      this.subchannelsState = true;
      this.subchannelSelected = false;
      // Using Vue Router
      await this.clearNewPrivateMessages()
      await this.loadPageData()
      const recipients = [...this.privateMessageRecipients];
      try {
      for (var i= 0; i< recipients.length; i++){
      this.loadSubchannel(recipients[i]['channel-id']);
      }
    } catch (e){
      console.log(e)
    }
    },
    async created(){
      this.observer = new IntersectionObserver(
          this.onElementObserved,
          {
            root: this.$el,
            threshold: 1.0,
          }
      );
      eventBus.$on('selectSubchannel', (data) => {
        this.selectSubchannel(data);
      })


    },
    methods: {
      ...mapActions("channels", ["fetchUserChannels", "clearNewPrivateMessages"]),
      ...mapActions("contents", ["fetchContents", "fetchContentById", "createContents"]),
      ...mapActions("activities", ["contentViewed"]),
      scrollToContentId(contentId) {
        this.$nextTick(() => {
          this.$refs[contentId][0].$el.scrollIntoView({ behavior: 'smooth' });});
      },
      sendDirectMessage(rid, uid, cid) {
          var params = {
            contentType: 'Message',
            contextId: cid,
            contextType: "Channel",
            type: 'PrivateMessage',
            contextChannelId: cid,
            contextRallyId: rid,
            status: 1,
            body: this.dmText
          };
          this.createContents(params);
          this.dmText = ""
      },
      async loadPageData() {
          await this.fetchUserChannels({userId: userInfo().user_id})
          this.loadAttempted = true
      },
      cancelNewInline() {
        this.contents.shift();
      },
 
      newInline(id) {
        eventBus.$emit('maybeDirectMessageEvent' + id)
      },
      onElementObserved(entries) {
        entries.forEach(({ target, isIntersecting}) => {
          if (!isIntersecting) {
            return;
          }
          let contentId = target?.attributes?.contentid?.value;
          let contentType = target?.attributes?.contenttype?.value;
          if (contentId && contentType){
            let params = {}
            params['contentId'] = contentId
            params['contentType'] = contentType
            params['actionType'] = 'viewed'
            params['rallyId'] = this.rallyId
            params['userId'] = userInfo()['id']
            this.contentViewed(params);
          }
        })},
      perform(method, params) {
        // Execute whatever method name came from the ToolBar.
        params ? this[method](params) : this[method]();
      },
      async selectSubchannel(subchannelId) {
        this.activeSubchannelId = subchannelId;

        for (const c of this.selectedChannels || []) {
          var params = {
            contextType: "Channel",
            contextChannelId: c,
            contextId:c,
            contentType: 'Message',
            include: ["shares", "attachments"],
            userId: "*",
            // privateMessageChannel: this.privateMessageRecipientsByChannelId[parseInt(c)],
            privateMessageChannel: this.channelsById[parseInt(c)],
            privateMessageRecipient: this.privateMessageRecipientsByChannelId[parseInt(c)],
            ignorePagination: true
          };
          await this.fetchContents(params);
          if (this.isMobile) {
            this.subchannelsState = false;
          }
        }
        const contentIdFromUrl = this.$route.query.cid
        if(contentIdFromUrl) {
          this.scrollToContentId(contentIdFromUrl);
        }
      },
      async loadSubchannel(subchannelId) {

        var params = {
          contextType: "Channel",
          contextChannelId: subchannelId,
          contextId:subchannelId,
          contentType: 'Message',
          include: ["shares", "attachments"],
          userId: "*",
          // privateMessageChannel: this.privateMessageRecipientsByChannelId[parseInt(c)],
          privateMessageChannel: this.channelsById[parseInt(subchannelId)],
          privateMessageRecipient: this.privateMessageRecipientsByChannelId[parseInt(subchannelId)],
          ignorePagination: true
        };
        await this.fetchContents(params);
        
      },
      toggleSubchannels() {
        this.$router.push({
          path: `/messaging`})
        this.subchannelsState = !this.subchannelsState;
      },
    },
    updated(){
      if (this.$route.params.activeSubchannelId && !this.subchannelSelected) {
        this.selectSubchannel(parseInt(this.$route.params.activeSubchannelId));
        this.subchannelsState = false;
        this.subchannelSelected = true;

      }
    },
  };
</script>
