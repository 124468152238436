import Vue from "vue";
import Router from "vue-router";

import ActivityFeedPage from "../pages/ActivityFeedPage.vue";
import AdminPage from "../pages/AdminPage.vue";
import AccountCreationPage from "../pages/AccountCreationPage";
import MembersPage from "../pages/MembersPage";
import MemberDetailsPage from "../pages/MemberDetailsPage";
import NewRally from "../pages/NewRally";
import LoginPage from "../pages/LoginPage";
import DashboardPage from "../pages/DashboardPage.vue";
import InvitationGate from "../pages/InvitationGate";
import RallyPage from "../pages/RallyPage";
import ChildRalliesPage from "../pages/ChildRalliesPage";
import RallyGate from "../pages/RallyGate";
import RallySettings from "../pages/RallySettings";
import EditChannelPage from "../pages/EditChannelPage";
import EditMessagePage from "../pages/EditMessagePage"; 
import CreateMessagePage from "../pages/CreateMessagePage";
import CalendarPage from "../pages/CalendarPage";
import MessagingPage from "../pages/MessagingPage";
import ResetPasswordEmailPage from "../pages/ResetPasswordEmailPage";
import SentimentsPage from "../pages/SentimentsPage.vue";
import StandardContentIndex from "../pages/StandardContentIndex";
import StandardContentNew from "../pages/StandardContentNew";
import StandardContentShow from "../pages/StandardContentShow";
import UserSettings from "../pages/UserSettings";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import { userInfo } from "../common/user-info";
import { store } from "../store/index.js";
import {redirectToChannel, getCapOpenParam} from "@/common/utils";
import { RH_URL} from "../common";
import MarketingSiteProxy from "../components/MarketingSiteProxy";


Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/home/*",
      component: MarketingSiteProxy
    },
    {
      path: "/admin",
      component: AdminPage,
      meta: { title: "Admin" }
    },
    {
      path: "/settings",
      component: UserSettings,
      meta: { title: "My Settings" }
    },
    {
      path: "/messaging",
      component: MessagingPage,
      name: "messaging",
      meta: { title: "Messaging" }
    },
    { path: "/calendar", component: CalendarPage, meta: { title: "Calendar" } },
    {
      path: "/feed",
      component: ActivityFeedPage,
      name: "feed",
      meta: { title: "Activity Feed" }
    },
    {
      path: "/messaging/:activeSubchannelId",
      component: MessagingPage,
      name: "messaging",
      meta: { title: "Messaging" }
    },
    {
      path: "/:rallyId/edit",
      component: RallySettings,
      name: "rallySettings",
      meta: { title: "Rally Settings" }
    },
    {
      path: "/:rallyId/gate",
      component: RallyGate,
      name: "gate",
      props: true
    },
    {
      path: "/:rallyId/igate",
      component: InvitationGate,
      name: "gate",
      props: true
    },
    {
      path: "/:rallyId/editMessage/:id",
      component: EditMessagePage,
      meta: { title: "Edit Message" }
    },
    {
      path: "/:rallyId/createMessage",
      component: CreateMessagePage,
      meta: { title: "Create Message" }
    },
    {
      path: "/:rallyId/sentiments/:share_id",
      component: SentimentsPage,
      name: "sentiments",
      meta: { title: "Sentiments" },
      props: route => ({ likeType: route.query.likeType })
    },
    {
      path: "/:rallyId/:channelSlug",
      component: RallyPage,
      name: "channel",
      meta: { title: "Rally Channel" }
    },
    {
      path: "/:rallyId/:channelSlug/edit",
      component: EditChannelPage,
      name: "edit_channel",
      meta: { title: "Settings" }
    },
    {
      path: "/:rallyId/:channelSlug/:id",
      //component: SelectedEventPage,
      name: "edit_content",
      component: RallyPage,
      meta: { title: "Content" }
    },

    {
      path: "/start",
      component: NewRally,
      meta: { title: "New Rally" }
    },
    { path: "/resetpasswordemail",
      component: ResetPasswordEmailPage,
      meta: { title: "Reset Password Email" }
    },
    { 
      path: "/resetpassword",
      component: ResetPasswordPage,
      name: "resetpassword",
      meta: { title: "Reset Password" }
    },
    {
      path: "/:rallyId/events/new",
      component: StandardContentNew,
      name: "events",
      meta: { title: "New Event" }
    },
    { path: "/", component: LoginPage, meta: { title: "Login" } },
    { path: "/login", component: LoginPage, meta: { title: "Login" } },
    { path: "/createAccount", component: AccountCreationPage, meta: { title: "Create Account" } },
    { path: "/rallies",
      component: DashboardPage,
      beforeEnter: (to, from, next) => {
        next();
      },
      meta: { title: "My Rallyhood" }
    },
    {
      path: "/:rallyId",
      component: RallyPage,
      name: "Rally Page"
    },

    // otherwise redirect to home
    { path: "*", redirect: "/rallies", meta: { title: "Rallies" } }
  ]
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (to.query.invt) {
    console.log("HAS INVITE")
    if (!to.query.rallyId && !to.params.rallyId) {
        console.log("NO RALLYID")
      console.log(to.params)
        console.log("REDIRECTING TO LOGIN")
        console.log(to.query)
        console.log(to.path)
        console.log("-----------------")
        let rallyId = to.path.replace(/^\/|\/$/g, '');
        next( { path: "/login", query: { ...to.query, rallyId: rallyId } });
        return;
    } else {
      console.log("HAS RALLY ID")
      // Extract the ID from the path
      const id = to.params.id || to.path.split('/')[1];

      // Check if the 'c' query parameter is not 'true'
      if (to.query.c) {
        // Check if the current path is not already ending with '/igate'
        if (!to.path.endsWith('/igate') && !to.path.includes("/login")) {
          // Construct the new path
          console.log("CONSTRUCTING NEW PATH: ", id)
          const newPath = `/${id}/igate`;

          // Redirect to the new path with the same query parameters
          next({path: newPath, query: to.query});
          return; // Ensure no further navigation occurs
        }
      }
    }
  }
  if (!document.getElementById("vue_component_name_id")) {
    const publicPages = ["/", "/login", "/resetpassword", "/resetpasswordemail", "/register", "/createAccount"];
    const authRequired = !publicPages.includes(to.path) && !to.path.includes("/home/") && !to.path.includes("/igate/");
    const loggedIn =  userInfo();

    if (authRequired && !loggedIn && !to.query.invt)  {
      const url = new URL(window.location.href);
       // Get the query parameters
       const params = new URLSearchParams(url.search);
       const pwtoken = params.get('prt'); // "2"
       if (pwtoken){
         //prt=rZsYx6PtHtvxisr8CJT5/1/010001917a81b531-aa6e6457-bb27-4874-ac94-9ace5c19313d-000000/Gj4SIG1uKdwdpf_A_6ACXCBZL44=388
         router.push("/resetpassword?prt=" + pwtoken.split('/')[0]);
       } else {
        return next({ path: "/login", query: { ...to.query, redirect: to.fullPath } });
       }
    } else {
      if (window.location.href.indexOf(RH_URL()) < 0 && window.location.href.indexOf("payments") >= 0){
        const oldUrl = new URL(window.location.href);
        const rhUrl = new URL(RH_URL())
        oldUrl.protocol = rhUrl.protocol
        oldUrl.host = rhUrl.host
        oldUrl.port = rhUrl.port
        let newUrl = oldUrl.href +  "?cap_open=" + this.getCapOpenParam()
        console.log(newUrl)
        window.location.href = newUrl
      } 
      //http://localhost:8081/51195/fundraisers/19075225/payments?cap_open=1
      if (window.location.href.indexOf("/dates/") >= 0){
        window.location = window.location.href.replace("dates", "events")
      }
    }
  }

  next();
});
