<template>
<span>
  <span v-if="member && memberUser && roleOrStatus && active">
  <article class="member">
    <MemberElipsisMenu 
                       :rallyId="this.rallyId"
                       :memberId="this.member.id"
                       :member = "member"
                       :memberUser="memberUser"
    />
    <UserAvatar :pUserId="memberUser.id" type="author-avatar" :pMembership="member"/>
    <div class="name">{{ memberUser.attributes["first-name"] }} {{ memberUser.attributes["last-name"] }} ({{ roleOrStatus }})</div>
    <div v-if="this.$attrs.isAdmin" class="email">
      {{ member.attributes.email }} 
    </div>

    <div v-if="member.attributes['current-position']" class="current-position"> 
      <span>Position or Role:</span>
      {{ member.attributes["current-position"] }}
    </div>
    <div v-if="member.attributes['expertise']" class="expertise">
      <span>Expertise:</span>
      {{ member.attributes["expertise"] }}
    </div>
    <div v-if="member.attributes['additional-info']" class="additional-info">
      <span>Other Info:</span>
      {{ member.attributes["additional-info"] }}
    </div>
    <div> 
    <button v-if="this.$attrs.isAdmin && requested" @click="doAcceptInvitation" style="margin-left:0" class="primary">Approve</button>
    <button v-if="this.$attrs.isAdmin && requested" @click="doRejectInvitation" class="danger">Reject</button>
    </div>
  </article>
  </span>
  <span v-if="member && roleOrStatus && !active">
  <article class="member">
    <MemberElipsisMenu  v-if="this.$attrs.isAdmin || this.isMe" :rallyId="this.rallyId" :member="member" :memberUser="memberUser"/>
    <UserAvatar :pUserId="memberUser.id" type="author-avatar" :pMembership="member"/>
    <div class="name">{{ memberUser.attributes["first-name"] }} {{ memberUser.attributes["last-name"] }} ({{ roleOrStatus }})</div>
    <div v-if="this.$attrs.isAdmin" class="email">
      {{ member.attributes.email }} 
    </div>
    <div>
    <button v-if="this.$attrs.isAdmin && requested" @click="doAcceptInvitation" style="margin-left:0" class="primary">Approve</button>
    <button v-if="this.$attrs.isAdmin && requested" @click="doRejectInvitation" class="danger">Reject</button>
    </div>
  </article>
  </span>
  </span>
</template>

<script>
  import MemberMixin from "./MemberMixin";
  import { mapState, mapActions, mapGetters } from "vuex";
  import { userInfo } from "../common/user-info";
  export default {
    mixins: [MemberMixin],
     computed: {
      ...mapState("user", ["usersById"]),
      isMe() {
        return userInfo().user_id == this.memberUser.id
       },
       roleOrStatus(){
         return this.member.attributes.status !== "Active" ?  this.member.attributes.status : this.member.attributes.role
       },
       requested(){
         return this.roleOrStatus == "Requested"
       },
       active(){
         return this.member.attributes.status == "Active"
       },
       invited(){
         return this.member.attributes.status == "Invited"
       },
     },
     methods: {
      ...mapActions("memberships", ["createMemberInvite","acceptInvite", "rejectInvite"]),
      isAdmin() {
        // TODO: Rally admin should be able to see everyone's profile.
        return this.$attrs.isAdmin
      },
      selectedRally: function() {
        return this.allRalliesByRallyId[parseInt(this.$route.params.rallyId)];
      },
      myRole() {
        return this.selectedRally.membership ? this.selectedRally.membership.attributes["role"] : "Friend";
      },
      doAcceptInvitation() {
       this.acceptInvite({member:this.member, rallyId: this.$attrs.rallyId })
      },
      doRejectInvitation() {
       this.rejectInvite({member:this.member, rallyId: this.$attrs.rallyId })
      },
     }
  };
</script>
