<template>
  <article v-if="checkReloadData" >
    <img class="avatar" :src="callAvaUrl(eventAvatar)" />
    <ContentElipsisMenu v-if="isAdmin || userIsOwner(this.content)"
                        :content="content"
                        :rallyId="this.rallyId"
                        :channelId="channelId"
                        contentType="Fundraiser" 
                        hide-sentiments="true"
                        :can-manage="true"
                       :can-promote="true"
                       :can-share="true"
                        :openRhWebOnEdit="true" />
    <div class="left" style="width: 65%; margin-top: -60px;">
      <h1 class="title">{{ content.attributes.title }}</h1>
      <div v-if="content.attributes['from-date']" class="full-date">
        <i class="fal fa-calendar icon"></i>
        <div class="date-only">
          <b>{{doFormatEventLine1(content.attributes)}}</b>
        </div>
        <div class="time-only">
          <b>{{doFormatEventLine2(content.attributes)}}</b>
        </div>
      </div>
      <div v-if="this.additionalDateFields && this.additionalDateFields.length > 0" class="additional-dates">
        <div v-for="d in this.additionalDateFields" :key="d.id" >
          <i class="fal fa-calendar icon" style="display: list-item; color: gray; font-size: 11pt; margin-top: 5px;"></i>
          <div class="additional-date">
            {{doFormatEventLine1(d.attributes)}} ({{doFormatEventLine2(d.attributes)}})
          </div>
        </div>
      </div>
      <div v-if="content.attributes.description && content.attributes.description.length > 0">
        <h2>Event Details</h2>
        <div class="description" v-html="content.attributes.description"></div>
      </div>
      <GmapMap
        v-if="addressInfo && addressInfo.attributes.latitude"
        :center="{lat:addressInfo.attributes.latitude, lng:addressInfo.attributes.longitude}" 
        :zoom="15"
        style="width: 300px; height: 250px"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in this.getMarkers()"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click="center=m.position"
        />
      </GmapMap>


    </div>
    <div class="right" style="margin-top: 15px;" v-if="this.content">
      <PayableEventParticipation :contextId="content.id" :rallyId="rallyId" :content="content" />
      <br />
      <SimplePayableEventParticipation v-if="expressInterestEnabled" :contextId="content.id" :rallyId="rallyId" :payables="payables" participation-type="express_interest" />
      <br/>
      <SimplePayableEventParticipation v-if="isWaitListed && iHazWaitliz" :contextId="content.id" :rallyId="rallyId" :payables="payables" participation-type="waitlist" />

      <div v-if="(isAdmin ||  this.userIsOwner(this.content)) && this.publicEvent && !this.showLink" class="fundraiser-link-share-section" id="share-area-15422180">
        <div class="span" style="font-size: 12pt; color: grey; display: inline-block; padding-top: 25px;">
          <div class="rh-help fal fa-question-circle" data-content="Creates a link you can share with other people outside of Rallyhood so they can register for this event." data-title="Shareable Link" style="padding-right: 3px;" aria-hidden="true" data-original-title="" title=""></div>
            GET SHAREABLE LINK
            <a style="font-size: 16pt; color: green; padding-left: 10px;" @click="doShowLink" class="shareable-fundraiser-link-button fal fa-link" href="#" aria-hidden="true"></a>
        </div>
      </div>


      <div v-if="this.showLink" class="col col-sm-12" style="padding-top: 25px;">
        <input type="text" name="shareable-link" id="shareable-link" :value="seUrl" class="shareable-fundraiser-link" style="width: 100%; border: 0px;">
      </div>
      <div v-if="this.showLink" class="col col-sm-12" style="padding-top: 25px; text-align:right;">
        <a id="copy-fundraiser-link" @click="copyShareableLink" class="flat-button-tertiary copy-fundraiser-link" href="#">Copy Link</a>
      </div>
      <div v-if="iHazPurchaz && content.attributes['online-location']">
        <hr/>
        <h2>Meeting Details</h2>
        <i class="fal fa-video" style="margin-bottom: 10px;">&nbsp;&nbsp;{{ content.attributes['online-location'] }}</i>
        <i class="fal fa-key">&nbsp;&nbsp;{{ content.attributes['online-location-password'] }}</i>
        <hr/>
      </div>
      <h2>Cost</h2>
      <ul class="costs">
        <li v-for="payable in this.payables" :key="payable.id" >
          <span :style="costStyle(payable)">{{ payable.attributes.title }} ({{ formatCurr(payable.attributes.cost) }})</span>
          <div class="availability" :style="availabilityStyle(payable)">{{ soldText(payable) }}
            <span style="margin-top: 5px; color: #337ab7" v-if="isSoldOut(payable) && isWaitListed(payable)">
              <span v-if="getAmIOnWaitlist(payable.id)" @click="toggleWaitlistModal(payable)">Remove Me From Waitlist</span>
              <span v-else @click="toggleWaitlistModal(payable)">Add Me to Waitlist</span>
            </span>
          </div>
        </li>
      </ul>
      <SimplePayableEventParticipation v-if="isWaitListed && showWaitlistModal"
                                       :contextId="content.id"
                                       :rallyId="rallyId"
                                       :payables="waitlistPayable"
                                       participation-type="waitlist"
                                       hide-button="true"
                                       :modal-close-method="closeWaitlistModal"/>
      <h2 v-if="iHazPurchaz">My Purchases</h2>
      <ul class="costs">

        <div v-for="payable in this.payables" :key="payable.id">
          <div v-if="getMyPurchaseById(payable.id)">
            <li>
              {{ getMyPurchaseById(payable.id).attributes.quantity }} {{ payable.attributes.title }} ({{ formatCurr(payable.attributes.cost) }})
              <div>
                <a v-if="organizer && organizer.attributes && organizer.attributes['contact-email']" class="minor" :href="'mailto:' + organizer.attributes['contact-email'] + '?subject=Requesting a refund for ' + content.attributes.title + ' : ' + payable.attributes.title" >
                  Request a Refund
                </a>
              </div>
              <div>
                <ManageAttendees :payable="payable" :rally-id="rallyId"  />
              </div>
            </li>
          </div>
        </div>
      </ul>
      <hr/>
      <h2 v-if="iHazIntrez">Interested In</h2>
      <ul class="costs">
        <div v-for="payable in this.payables" :key="payable.id">
          <div v-if="getInterestPart(payable.id)">
            <li>{{ getInterestPart(payable.id).attributes.quantity }} {{ payable.attributes.title }} ({{ formatCurr(payable.attributes.cost) }})</li>
          </div>
        </div>
      </ul>
      <h2 v-if="content.attributes['from-date'] || content.attributes['all-day-start-date']">
        Date &amp; Time</h2>
      <div v-if="content.attributes['from-date']" >
        {{doFormatEventLine1(content.attributes)}}
      </div>
      <div v-if="content.attributes['from-date']">
        {{doFormatEventLine2(content.attributes)}}
      </div>
      <div v-if="addressLine1">
        <hr/>
        <h2>Location</h2>
              <div v-if="addressLine1" >{{ addressLine1 }}</div>
              <div v-if="city" >{{ city }}, {{ state }} {{ zip }}</div>
              <a :href="gmapUrl" target="_blank" >Map</a>
      </div>
      <div v-if="this.deadlineDate">
        <hr/>
        <h2>Deadline</h2>
        <div>{{ this.deadlineDateFormatted }}</div>
      </div>
      <div>
        <hr/>
        <ContentAttachments :readOnly="true" :content="content" :rallyId="parseInt(this.rallyId)" :channelId="parseInt(channelId)"/>
      </div>
      <div v-if="organizer && organizer.attributes && organizer.attributes['contact-phone']">
        <hr/>
        <h2 >Organizer</h2>
        <div>
          <a v-if="organizer && organizer.attributes && organizer.attributes['contact-email']" href="'mailto:' + organizer.attributes['contact-email']" >
            {{ this.organizer.attributes["contact-email"] }} 
          </a>
        </div>
        <div>
          <a href="">
            {{ this.organizer.attributes["contact-phone"] }}
          </a>
        </div>
      </div> 
    </div>

    <div  v-if="participants && participants.length > 0 && content && content.attributes && content.attributes.privacy == 0">
      <hr/>
    <h2>Registrants</h2>
    <PayableParticipations :payables="this.payables" :channelId="channelId" :rallyId="this.rallyId" />
    </div>
    <Comments :shareId="content.relationships['context-share'].data.id" :avatarFile="content.attributes['creator-avatar']" :channelId="channelId" :rallyId="this.rallyId"/>
  </article>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatCurrency, findOrganizerParticipation, formatDate, formatDateNoTimezoneConversion,
   formatEventLine1, formatEventLine2,
  avaUrl, railsTzToMomentTz} from "../../../common";
import { userInfo } from "../../../common/user-info"
import ContentPayableMixin from "../../ContentPayableMixin";
import DatetimeMixin from "../../DatetimeMixin";
import AddressMixin from "../../AddressMixin";
import { router, RH_URL } from "../../../common";
import { eventBus } from "../../../main.js";
import { VueGoogleMaps } from "vue2-google-maps"
import { GmapMap } from "vue2-google-maps"
import { GmapMarker }  from "vue2-google-maps"

export default {

  mixins: [ContentPayableMixin, DatetimeMixin, AddressMixin], 
  name: "PayableEventDetails",
  props: ["id", "rally", "channel", "content"],
    data() {
      return {
        showLink: false,
        isLoading: true,
        showWaitlistModal: false,
        waitlistPayable: []
      };
  },
  computed: {
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    ...mapState("participations", ["participationById"]),
    ...mapState("shares", ["sharesByContentId"]),
    ...mapState("user", ["usersById"]),
    ...mapState("rallies", ["allRalliesByRallyId", "rallyChannelsByRallyId"]),
    gmapUrl() {
      return "https://www.google.com/maps/search/?api=1&query=" + this.addressInfo.attributes.latitude + "," + this.addressInfo.attributes.longitude + "" 
    },

    publicEvent(){
      return true
      /*console.log("this.payables")
      console.log(this.payables)
      if (this.payables && this.payables.length > 0 && this.payables[0].attributes['privacy']){
        return  this.payables[0].attributes['privacy'] == 1 || this.payables[0].attributes['privacy'] == '1'
      } else {
        return false;
      }*/
    },

    isAdmin() {
      // TODO: Rally admin should be able to see everyone's profile.
      return this.myRole === "Admin" || this.myRole === "Owner";
    },
    selectedRally: function() {
      return this.allRalliesByRallyId[parseInt(this.$route.params.rallyId)];
    },
    myRole() {
      return this.selectedRally.membership ? this.selectedRally.membership.attributes["role"] : "Friend";
    },

    channelKey(){
      return  encodeURI(btoa("CHANNEL_KEY:" + this.content.id ).replace(/[+=/]/g, ""))
    },

    seUrl(){
      return RH_URL() + this.rallyId + "/fundraisers/" + this.content.id + "?channel_key=" + this.channelKey
    },

    additionalDateFields(){
      let c = this.allContentByContextTypeAndId[this.content.id]
      if (c && c.filter){
      let df =  c.filter(function(s) {
        return s.attributes.type == 'ChildPayableEvent'
       })
      return df 
      } else {
        return []
      }

      //return this.allContentByContextTypeAndId[parseInt(this.contextId)];
    },
    deadlineDateFormatted(){
      if (this.deadlineDate){
        return this.deadlineDate.format('MMM DD, YYYY')
      } else {
        return null
      }
    },
    deadlineDate() { 
      let c = this.allContentByContextTypeAndId[this.content.id]
      if (c && c.filter){
       let de =  c.filter(function(s) {
         if (s.attributes.type == 'DeadlineEvent'){
           return s
         } 
       })
       if (de && de.length > 0){
         if (de[0].attributes['to-date']){
            return moment(de[0].attributes['to-date'], 'MM/DD/YYYY').subtract(1, "days");
         } else {
           return null
         }
       } else {
         return null
       }
      } else {
        return null
      }
    },
    expressInterestEnabled(){
      return this.payables?.[0]?.attributes['hold-flag'] === true
    },
    allDay() {
      return this.content.attributes['all-day'] === 1
    },
    eventAvatar() {
      if(this.content && this.content.relationships && this.content.relationships.avatar) {
        return this.contentById[this.content.relationships.avatar.data.id];
      } else {
        return ''
      }
    },

    organizer() {
      return findOrganizerParticipation(this.content.relationships.participations.data, this.participationById);
    },
    user() {
      return userInfo()
    },

    payables() {
      let c = this.allContentByContextTypeAndId[this.content.id]
      if (c && c.filter){
       return c.filter(function(s) {
        return s.attributes.type == 'Payable'
       })
      } else {
        return []
      }
    },
    participants() {
      if (this.payables && this.payables.length > 0){
        return this.payables[0].relationships.participations.data
      } else {
        return []
      }

    },
    openRhWebUniqueId(){
      return this.content.id
    },
    iHazPurchaz() {
      //var payablez = this.allContentByContextTypeAndId[this.content.id]
      var haz = false
      if (this.payables){
          this.payables.forEach(p => {
            if (this.getMyPurchaseById(p.id)){
              haz = true;
            }
        })
      }
      return haz;
    },
    iHazIntrez() {
      var haz = false;
      //var payablez = this.allContentByContextTypeAndId[this.content.id]
      if (this.payables){
          this.payables.forEach(p => {
            if (this.getInterestPart(p.id)){
              haz = true;
            }
        })
      }
      return haz;
    },
    iHazWaitliz() {
      var haz = false;
      //var payablez = this.allContentByContextTypeAndId[this.content.id]
      if (this.payables){
        this.payables.forEach(p => {
          if (this.getMyWaitlistById(p.id)){
            haz = true;
          }
        })
      }
      return haz;
    },

  },
  async created() {
    await this.loadPageData()
    //eventBus.$on('editSpecialEvent', (id) => { 
      //eventBus.$emit('openRHWebSpecialEventsEdit', id) 
    //})
    //eventBus.$on('manageSpecialEvent', (id) => {
      //eventBus.$emit('openRHWebSpecialEventsManage', id) 
    //})
    await this.fetchAddressesByIds({addressIds: [this.content.attributes['address-id']]})
    if(this.payables.length > 0 && this.isWaitListed(this.payables[0])) {
      await this.fetchMyWaitlist()
      await this.fetchMyInterested()
    }
    await this.fetchSharesByContentId({contentId: this.content.id, contextRallyId: this.rallyId, contextChannelId: this.channelId})
  },
  methods: {
    ...mapActions("contents", ["fetchContents"]),
    ...mapActions("locations", ["fetchAddressesByIds"]),
    ...mapActions("channels", ["fetchChannelKey"]),
    ...mapActions("shares", [ "fetchSharesByContentId"]),
    availabilityStyle(payable) {
      if (this.isSoldOut(payable)) {
        return "color: red"
      } else {
        return ""
      }
    },
    async fetchMyInterested() {
      await this.fetchContents({contextRallyId: this.rallyId,
        contextType: 'Content',
        contentType: 'Payable',
        type: 'Payable',
        contextId: this.content.id,
        participantId: userInfo().user_id,
        participantType: 'interested',
        participationStatus: 'interested',
        status: [0,1],
        ignorePagination: true
      })
    },
    async fetchMyWaitlist() {
      await this.fetchContents({contextRallyId: this.rallyId,
        contextType: 'Content',
        contentType: 'Payable',
        type: 'Payable',
        contextId: this.content.id,
        participantId: userInfo().user_id,
        participantType: 'waitlist',
        participationStatus: 'waitlist',
        status: [0,1],
        ignorePagination: true
      })
    },
    userIsOwner(c){
      return (c && c.attributes && c.attributes["creator-id"] == this.user.user_id)
    },
    getMarkers() {
      // generating markers for site map
        var markers = [];
        // remove this after lat long received from api.

        const tempLatLong = [
          { lat: this.addressInfo.attributes.latitude, lng: this.addressInfo.attributes.longitude },
        ];
       for(let i=0;i<tempLatLong.length;i++){
          markers.push({
              position: tempLatLong[i],
              title: '',
              //icon: this.getSiteIcon(1)    // if you want to show different as per the condition.         
            });
        }
        return markers;      
    },
    copyShareableLink: function(event) {
      let c = document.getElementById("shareable-link");
      c.select();
      document.execCommand("copy");
    },
    costStyle(payable) {
      if (this.isSoldOut(payable)) {
        return "text-decoration: line-through;"
      } else {
        return ""
      }
    },
    doShowLink(){
      this.fetchChannelKey({channelId: this.channelId, channelKey: this.channelKey})
      this.showLink = !this.showLink
    },
    doFormatSpecialEvent(d){
      return formatDateNoTimezoneConversion(d, railsTzToMomentTz(userInfo().timezone))
    },
    doFormatEventLine1(d) {
      return formatEventLine1(d, railsTzToMomentTz(userInfo().timezone))
    },
    doFormatEventLine2(d) {
      return formatEventLine2(d, railsTzToMomentTz(userInfo().timezone))
    },
    callAvaUrl(c){
      return avaUrl(c)
    },
    getParticipantUser(id){
      return this.usersById[this.participationById[id+ ""].attributes["user-id"]]
    },
    getParticipantName(id){
      var u = this.usersById[this.participationById[id+ ""].attributes["user-id"]]
      return u ? u.attributes["first-name"] + " " +  u.attributes["last-name"] : ""
    },
    getInterestPart(id) {
      return (this.user && this.user.user_id) ? this.participationById[id + "interested" + this.user.user_id] : null;
    },
    getMyPurchaseById(id) {
      return this.user && this.user.user_id ? this.participationById[id + "purchased" + this.user.user_id] : null
    },
    getMyWaitlistById(id) {
      return this.user && this.user.user_id ? this.participationById[id + "waitlist" + this.user.user_id] : null
    },
    getAmIOnWaitlist(id) {
      return this.user &&
      this.user.user_id &&
      this.participationById[id + "waitlist" + this.user.user_id] &&
      this.participationById[id + "waitlist" + this.user.user_id].attributes.quantity > 0
    },
    formatCurr(val) {
      return formatCurrency(val);
    },
    isInfiniteInventory(payable) {
      return payable.attributes["infinite-inventory"]
    },
    isSoldOut(payable) {
      var amt = payable.attributes["amount"];

      if (!this.isInfiniteInventory(payable)) {
        if (amt == 0) {
          return true
        }
      }
      return false;
    },
    isWaitListed(payable) {
      return payable.attributes['waitlist-flag']
    },
    soldText(payable) {
      var amt  = payable.attributes["amount"];
      var maxp = payable.attributes["max-participants"];
      var totp = payable.attributes["participant-total"];

      var txt = totp + " Slots Filled";

      if (this.isSoldOut(payable)) {
        if (this.isWaitListed(payable)) {
          return "SOLD OUT -"
        } else {
          return "SOLD OUT";
        }
      }

      if (!this.isInfiniteInventory(payable)) {
        var diff = maxp - amt;
        txt = diff + " Slots Filled " + " of " + maxp;
      }
      return txt;
    },
    closeWaitlistModal() {
      this.showWaitlistModal = false;
    },
    toggleWaitlistModal(payable) {
      this.showWaitlistModal = !this.showWaitlistModal
      if(this.showWaitlistModal) {
        this.waitlistPayable = [payable]
      } else {
        this.waitlistPayable = []
      }
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
