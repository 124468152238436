<template>
  <article class="as-form">
    <form>
      <div class="toolbar">
        <div class="left">
          <a @click="$emit('perform', 'cancel')" class="back">
            <i class="fal fa-chevron-left icon"></i>
            Cancel
          </a>
        </div>
        <div class="right"> 
          <div v-if="isLoading" class="loading">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          <button
            @click.prevent="postContent()"
            class="primary"
            type="submit"
          >Post</button>
        </div>
      </div>
      <div class="wrapper">
        <div class="form-group">
          <label for="title">Title</label>
          <input
            v-model="content.attributes.title"
            id="title"
            name="title"
            type="text" 
          />
        </div>
        <div class="form-group">
          <label for="body">Body</label>
          <vue-editor
            v-model="content.attributes.body"
            :customModules="customModules"
            :editorOptions="editorOptions"
            :editorToolbar="customToolbar"
          ></vue-editor>
        </div>
        <div class="form-group stacked-labels checkboxes" v-if="this.allowNotifyImmediately()" >
          <label>
            <input v-model="content.attributes.priority" type="checkbox" />
            <div class="square"></div>
            <b>&nbsp; &nbsp; Notify Immediately</b>
            - This will send an email with your message to everyone in this
            Rally.
          </label>
        </div>
        <ContentAttachments
          v-if="content"
          ref="cAttach"
          :readOnly="false"
          :content="content"
          :rallyId="parseInt(this.rallyId)"
          :channelId="parseInt(this.channelId)"
          v-on:perform="perform"
        />
      </div>
    </form>
  </article>
</template>

<script>
import QuillMention from "quill-mention";
import { mapState, mapActions, mapGetters } from "vuex";
import MemberMixin from "../../MemberMixin";
import ContentMixin from "@/components/ContentMixin.vue";

export default {
  mixins: [MemberMixin, ContentMixin],
  name: "Message",
  props: ["channel", "content", "rally"],
  data() {
    return {
      isLoading: false,
      customModules: [{ alias: "quillMention", module: QuillMention }],
      customToolbar: [
        "bold",
        "italic",
        "underline",
        { list: "bullet" },
        { color: [] },
        { background: [] },
        //"image",
        "link",
        { align: [] }
      ],
      editorOptions: {
        modules: {
          quillMention: {
            allowedChars: /^[A-Za-z0-9\s]*$/,
            mentionDenotationChars: ["@"],
            source: async function(searchTerm, renderList) {
              // TODO: Query RMS
             // const raw =  this.memberSearchResults;

             const matchedPeople = await this.searchMembersByRally(searchTerm, this.rallyId);
             const raw = this.memberSearchResults;
             const users = this.profileByMembershipId;
             const members = this.membersByRallyId;

              // Parse the results to the format QuillMention expects:
              // { id: String, avatar: String, value: String }

              let results = [];
              //for (const result : raw) {
              for (const result of raw) {
                //alert(result);
                //alert(result.data);
                //alert(result.data.attributes);
                //alert(result.data.attributes.user.first_name);

                results.push({
               //   avatar: raw[result].avatar,
                  id: result.attributes['user-id'],
                  value: users[result.id].attributes['first-name'] + " " + users[result.id].attributes['last-name']
                });
              }

              // As you type, filter results on client side as well for faster response.
              if (searchTerm.length === 0) {
                renderList(results, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < results.length; i++) {
                  if (
                    ~results[i].value
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase())
                  ) {
                    matches.push(results[i]);
                  }
                }
                renderList(matches, searchTerm);
              }
            }.bind(this),
            renderItem(item) {
              //return `<img src="${item.avatar}">${item.value}`;
              return `${item.value}`;
            }
          }
        }
      }
    };
  },
  computed: {
    ...mapState("memberships", [
      "memberSearchResults",
      "profileByMembershipId",
      "membersByMemberId",
      "membersByRallyId"
    ]),
  ...mapState("rallies", ["allRalliesByRallyId"])},
  methods: {
    ...mapActions("memberships", ["searchMembers"]),
    postContent(){
      this.isLoading= true
      try{
        this.$emit('perform', 'save')
      } catch (e){
        console.log(e)
      }
    },
    perform(method, params) {
      if (this[method]) {
        params ? this[method](params) : this[method]();
      } else {
        this.$emit("perform", method, params);
      }
    },
    allowNotifyImmediately() {
      var thisRally = this.allRalliesByRallyId[this.rallyId]
      console.log("THISRALLY");
      console.log(thisRally.rally.attributes);
      if (thisRally.rally.attributes['disable-notify-immediate'] == true) {
        if (thisRally.rally.attributes['editable'] == true) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    searchMembersByRally(query, rallyId) {
      this.searchMembers({
        status: ["Active"],
        query: query,
        rallyId: rallyId,
        pageNumber: 1,
        queryField: 'name'
      });
    }
  }
};
</script>
