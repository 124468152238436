var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.myMembership)?_c('div',[_c('main',{class:['members']},[(this.memberSearchResults && this.memberSearchResults.length > 0)?_c('section',{class:[_vm.sectionClass, 'content-area']},_vm._l((_vm.memberSearchResults),function(member){return _c(_vm.listedComponentType,{key:member.id,tag:"component",attrs:{"rallyId":_vm.rallyId,"memberProp":member,"isAdmin":_vm.isAdmin}})}),1):_vm._e(),(this.noSearchResultsFlag)?_c('section',[_c('br'),_c('br'),_c('br'),_c('br'),_c('h3',{staticClass:"title",staticStyle:{"text-align":"center"}},[_vm._v("No results!")])]):_vm._e(),(
      //causing page to not load initially
       this.myMembership &&
          (!this.memberSearchResults ||
            this.memberSearchResults.length === 0) &&
          !this.noSearchResultsFlag
      )?_c('div',[_c('section',{class:[_vm.sectionClass, 'content-area']},_vm._l((this.sortedMemberList(this.members)),function(member){return _c(_vm.listedComponentType,{key:member.id,tag:"component",attrs:{"rallyId":_vm.rallyId,"memberProp":member,"isAdmin":_vm.isAdmin}})}),1),_c('infinite-loading',{ref:"infLoadMem",on:{"infinite":_vm.infiniteHandler}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"},[_c('br'),_vm._v(" "),_c('i',{staticClass:"fa fa-sync",on:{"click":_vm.reloadPage}},[_c('div',{staticStyle:{"font-family":"sans-serif"}},[_vm._v("Refresh")])]),_c('br'),_c('br'),_c('br')]),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"},[_c('br'),_vm._v(" "),_c('i',{staticClass:"fa fa-sync",on:{"click":_vm.reloadPage}},[_c('div',{staticStyle:{"font-family":"sans-serif"}},[_vm._v("Refresh")])]),_c('br'),_c('br'),_c('br')])])],1):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }