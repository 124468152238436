<template>
  <article v-if="checkReloadData">
    <img class="avatar" :src="callAvaUrl(eventAvatar)" />
    <ContentElipsisMenu v-if="this.content.attributes.editable"
                        :content="content"
                        :rallyId="this.rallyId"
                        :channelId="this.channelId"
                        contentType="Fundraiser"
                        hide-sentiments="true"
                        :openRhWebOnEdit="true"
                        :can-manage="true"
                        :can-promote="false"
                        :can-share="true"/>
    <div class="left">
      <h1 class="title">{{ content.attributes.title }}</h1>
      <div class="description" v-html="content.attributes.description"></div>
    </div>
    <div class="right">
      <GiveParticipation :contextId="contentId" :content="content" :rallyId="rallyId" />
      <h2 v-if="payables.length > 0">Giving Levels</h2>
      <ul class="costs">
        <li v-for="payable in this.payables" :key="payable.id">
          <div v-if="payable.attributes.title !== 'user-defined'">{{ payable.attributes.title }} ({{ formatCurr(payable.attributes.cost) }})</div>
          <div v-if="payable.attributes.title !== 'user-defined'"><div class="availability">{{ totalParticipation(payable) }}</div></div>
          <div v-if="payable.attributes.title === 'user-defined'">
            Donor Specified
          </div>
          <div v-if="payable.attributes.title === 'user-defined'">
            <div class="availability">{{ donorSpecifiedTotal(payable) }}</div>
          </div>
          <div>
            <a class="minor" :href="'mailto:' + organizer.attributes['contact-email'] + '?subject=Requesting a refund for ' + content.attributes.title + ' : ' + payable.attributes.title" v-if="organizer.attributes['contact-email']">
              Request a Refund
            </a>
          </div>
        </li>
      </ul>
      <div v-if="organizer && organizer.attributes">
        <h2>Organizer</h2>
        <div>
          <a href="'mailto:' + organizer.attributes['contact-email']" v-if="organizer.attributes['contact-email']">
            {{ this.organizer.attributes["contact-email"] }}
          </a>
        </div>
        <div v-if="organizer.attributes['contact-phone']">
          <a href="">
            {{ this.organizer.attributes["contact-phone"] }}
          </a>
        </div>
      </div>
    </div>
    <div style="height: 200px;">
      <ContentAttachments v-on:perform="perform" :readOnly="true" :content="content" :rallyId="parseInt(this.rallyId)" :channelId="parseInt(this.channelId)"/>
    </div>
    <Comments :shareId="content.relationships['context-share'].data.id" :avatarFile="content.attributes['creator-avatar']" :channelId="channelId" :rallyId="this.rallyId"/>

  </article>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { formatCurrency, findOrganizerParticipation, avaUrl } from "../../../common";
import ContentPayableMixin from "../../ContentPayableMixin";
import { userInfo } from "../../../common/user-info"
import {eventBus} from "@/main";
export default {
  mixins: [ContentPayableMixin],
  data() {
    return {
      loadAttempted: false
    }
   },
  name: "GivePayableGroupDetails",
  props: ["id", "rally", "channel", "content"],
  computed: {
    ...mapState("rfins", ["fundraiserDetailsById"]),
    ...mapState("contents", ["contentById", "allContentByContextTypeAndId"]),
    ...mapState("participations", ["participationById"]),
    ...mapState("shares", ["sharesByContentId"]),
    eventAvatar() {
      return this.contentById[this.content.relationships.avatar.data.id];
    },
    organizer() {
      return findOrganizerParticipation(this.content.relationships.participations.data, this.participationById);
    }
  },
  created() {
    eventBus.$on('editGive', (id) => {
      eventBus.$emit('openRHWebGivesEdit',id)
    })
    eventBus.$on('manageGive', (id) => {
      eventBus.$emit('openRHWebGivesManage',id)
    })
    this.user_id = userInfo();
  },
  methods: {
    ...mapActions("contents", ["fetchContents"]),
    formatCurr(val) {
      return formatCurrency(val);
    },
    donorSpecifiedTotal(p) {
     // var amt = p.attributes["amount"];
     // var maxp = p.attributes["max-participants"];
      var totp = p.attributes["participant-total"];
      var txt = "$" + totp  + " Donations Received";
      /*if (!p.attributes["infinite-inventory"]) {
        if (amt == 0) {
          return "SOLD OUT";
        }
        var diff = maxp - amt;
        txt = diff + " Slots Filled " + " of " + maxp;
      }*/
      return txt;
    },
    openRhWebUniqueId(){
      return this.content.id
    },
    totalParticipation(p) {
      var totp = p.attributes["participant-total"]
      var txt =  totp + " Donation(s)";

      return txt;
    },
    perform(method, params) {
      if (this[method]) {
        params ? this[method](params) : this[method]();
      } else {
        this.$emit("perform", method, params);
      }
    },
    log(message) {
      this.$log.debug(message);
    },
  }
};
</script>
