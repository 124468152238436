<template>
<div v-if="myMembership">
  <main :class="['members']">
    <section
      v-if="this.memberSearchResults && this.memberSearchResults.length > 0"
      :class="[sectionClass, 'content-area']"
    >
      <component
        v-for="member in memberSearchResults"
        :is="listedComponentType"
        :key="member.id"
        :rallyId="rallyId"
        :memberProp="member"
        :isAdmin="isAdmin"
      />
    </section>
    <section v-if="this.noSearchResultsFlag">
      <br />
      <br />
      <br />
      <br />
      <h3 class="title" style="text-align:center">No results!</h3>
    </section>

    <div
      v-if="
      //causing page to not load initially
       this.myMembership &&
          (!this.memberSearchResults ||
            this.memberSearchResults.length === 0) &&
          !this.noSearchResultsFlag
      "
    >
      <section :class="[sectionClass, 'content-area']">
        <component
          v-for="member in this.sortedMemberList(this.members)"
          :is="listedComponentType"
          :key="member.id"
          :rallyId="rallyId"
          :memberProp="member"
          :isAdmin="isAdmin"
        />
      </section>

      <infinite-loading  ref="infLoadMem" @infinite="infiniteHandler">
        <span slot="no-more"> <br/> <i @click="reloadPage" class="fa fa-sync" ><div style="font-family:sans-serif;">Refresh</div></i><br/><br/><br/></span>
        <span slot="no-results"> <br/> <i @click="reloadPage" class="fa fa-sync" ><div style="font-family:sans-serif;">Refresh</div></i><br/><br/><br/></span>
      </infinite-loading>
    </div>

  </main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";
import { findDefaultChannelOfType } from "../common";
import { userInfo } from "../common/user-info";
import {eventBus} from "@/main";
export default {
  props: ["rally", "channel"],
  data() {
    return {
      layout: {
        Directory: {
          component: "DirectoryMember",
          section: "directory"
        },
        Members: {
          component: "MembersMember",
          section: "friends"
        }
      },
    };
  },
  computed: {
    ...mapState("memberships", [
      "membersByRallyId",
      "memberSearchResults",
      "noSearchResultsFlag",
      "membersByUserId",
      "resultCountByRally"
    ]),
    ...mapState("user", ["usersById"]),
    members() {
      return this.membersByRallyId[this.rallyId];
    },
    channelSlug() {
      return this.$route.params.channelSlug
    },
    listedComponentType() {
      return this.layout[this.channel.attributes.type]["component"];
    },
    sectionClass() {
      return this.layout[this.channel.attributes.type]["section"];
    },
    toolbarOptions() {
      let options = new Array();
      return options;
    },
    isAdmin() {
      // TODO: Rally admin should be able to see everyone's profile.
      return this.myRole === "Admin" || this.myRole === "Owner";
    },
    myUserId() {
      return parseInt(userInfo().user_id);
    },
    myMembership() {
      return this.membersByUserId[this.myUserId];
    },
    isLoadingComplete(){
      return (this.membersByRallyId[this.rallyId] && this.resultCountByRally[this.rallyId] && this.resultCountByRally[this.rallyId] > 0) && (this.membersByRallyId[this.rallyId].length >= this.resultCountByRally[this.rallyId] -1 )
    },
    myRole() {
      return this.myMembership
        ? this.myMembership.attributes["role"]
        : "Friend";
    },
    rallyId() {
      return this.rally.id;
    }

  },
  watch: {
    
  },
  async created() {
    this.clearMemberSearchResults({});
   if (this.rally.attributes.editable) {
     await this.fetchMembersByRally({
       rallyId: this.rallyId,
       status: ["Active", "Invited", "Requested", "Affiliated"],
       customPageSize: 20
     });
   } else {
     await this.fetchMembersByRally({
       rallyId: this.rallyId,
       status: ["Active", "Affiliated"],
       customPageSize: 20
     });}
  },
  methods: {
    ...mapActions("memberships", [
      "fetchMembersByRally",
      "clearMemberSearchResults",
      "clearMemberState",
      "reinviteMember",
        "resendInvitation"
    ]),
    ...mapActions("rallies", [ "fetchRalliesWithInfo"]),
    reloadPage(){
      location.reload()
    },
    sortedMemberList(mems){
        return [...this.filterRequestee(mems), ...this.filterNonRequestee(mems)]
    },
    filterNonRequestee(mems) {
      return mems.filter(item => item.attributes.status != 'Requested');
    },
    filterRequestee(mems) {
      return mems.filter(item => item.attributes.status === 'Requested');
    },
    filterMembers() {
      this.clearMemberSearchResults({});
      this.clearMemberState({});
      if (this.rally.attributes.editable) {
        this.fetchMembersByRally({
          rallyId: this.rallyId,
          status: ["Active", "Invited", "Affiliated"],
          customPageSize: 20
        });
      } else {
        this.fetchMembersByRally({
          rallyId: this.rallyId,
          status: ["Active", "Affiliated"],
          customPageSize: 20
        });
      }
      if (this.rally.attributes.editable) {
        this.fetchMembersByRally({
          rallyId: this.rallyId,
          status: ["Requested"],
          customPageSize: 20
        });
      }
    },
    refreshMembers(role, status) {
      this.clearMemberSearchResults({});
      this.clearMemberState({});
      //we need to query for all status until we get to later pages of data at which time the current members admin flag will be loaded
      this.fetchMembersByRally({
        rallyId: this.rallyId,
        role: role,
        status: status,
        customPageSize: 20
      });
      //this.$refs.infLoad.stateChanger.reset(); 
    },
    //we have to pass in the status filter for subsequent pages so we get a correct total count...by default the status is filtered anyhow but...
    //there seems to be a bug with the backend total count
    async loadNextPage(){
      await this.fetchMembersByRally({
        rallyId: this.rallyId,
        status: this.rally.attributes.editable ? ["Active", "Invited", "Requested", "Affiliated"]: ["Active" , "Affiliated"],
        customPageSize: 20
      });
    },
    async infiniteHandler($state) {
      let beforesize = this.members.length
      console.log("sci in:")
      await this.loadNextPage();
      if (beforesize == this.members.length){
        console.log("complete!!!!" + beforesize + "=>" + this.members.length)
        $state.complete(); 
      } else {
        console.log("loaded!!!!" + beforesize + "=>" + this.members.length)
        $state.loaded();
      }
      
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
