<template>
  <div  >
    <DashboardNav /> 
    <DarkOverlay />
    <main class="dashboard">
      <ToolBar
        :options="toolbarOptions"
        v-on:perform="perform"
      />
        <section  class="content-area" v-if="this.rallySearchResults && this.rallySearchResults.length > 0">
          <Rally
            v-for="(rally, $index) in this.rallySearchResults"
            :key="$index"
            :rally="rally"
          > 
          </Rally>
        </section>
      <section class="content-area"   v-if="!(this.rallySearchResults && this.rallySearchResults.length > 0 && this.isDashboardLoaded)">
        <draggable v-model="this.rallyListing" group="people" @start="onDragStart" @end="onDragEnd">
        <Rally
          v-for="(rally, $index) in this.rallyListing"
          :key="$index"
          :rally="rally"
        >
        </Rally>

        </draggable>
        <infinite-loading slot="no-results" ref="infLoad2" @infinite="infiniteHandler">
          <span slot="no-more"> <br/> <i @click="reloadPage" class="fa fa-sync" ><div style="font-family:sans-serif;">Refresh</div></i><br/><br/><br/></span>
          <span slot="no-results"> <br/> <i @click="reloadPage" class="fa fa-sync" ><div style="font-family:sans-serif;">Refresh</div></i><br/><br/><br/></span>
        </infinite-loading>
      </section>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";
import { findDefaultChannelOfType } from "../common";
import RallyPaginationMixin from "../components/RallyPaginationMixin";
import { eventBus } from "../main.js";  
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed
} from "@capacitor/core";
import { userInfo } from "../common/user-info";
const { PushNotifications } = Plugins;

export default {
  mixins: [RallyPaginationMixin],
  name: "DashboardPage",
  data() {
    return {
      //parentRallyId: null,
      drag: false,
      page: 1,
      isDashboardLoaded: false,
      // infiniteRallies: [],
      tempSortOrder: {},
      prevLoadCount: 0,
      prevLoadCountSameCount: 0,
      show_create: false,
      polling_int: null,
      newMessage: {
        title: null,
        body: null
      }
    };
  },
  computed: {
    ...mapState("rallies", [
      "allRallies",
      "myRallies",
      "rallyChannelsByRallyId",
      "ralliesByParent",
      "allRalliesByRallyId", "resultCountByRallyId", "rallySearchResults"
    ]),
    ...mapState("contents", ["contentById"]),
    ...mapState("user", ["status", "usersById"]),
    ...mapState("memberships", ["myMembershipByRallyId"]),
    rallyListing() {
      if (!this.isDashboardLoaded) {
        return []; // or any default value that signifies loading state
      }
      let rs = []
      if (this.parentRallyId) {
        rs = [...this.myRallies[parseInt(this.parentRallyId)]]; // Copying the array using spread operator
      } else {
        rs = [...this.myRallies]; // Copy the myRallies array
      }

      // Remove entries where rally attribute is missing
      rs = rs.filter(item => item?.rally?.attributes?.title);
      rs = rs.filter(item => item?.rally?.attributes?.viewable);
      rs = rs.filter(item => (item?.rally?.attributes?.status == "Active") || (item?.rally?.attributes?.status == "Archived" && item?.membership?.attributes?.role == "Owner" && item?.membership?.attributes?.status == "Active") );

      if (rs && Array.isArray(rs)) {
       rs = this.membershipSort(rs)
      } else {
        console.warn("rs is not an array or is undefined");
      }
      
      return rs
    },
    parentRallyId() {
      return this.$route.params.parentRallyId;
    },
    resultCount() {
      return this.resultCountByRallyId[this.parentRallyId > 0 ? this.parentRallyId : 1]
    },
    toolbarOptions() {
      let options = new Array();
      //options.push({ onClick: "openSearch", params: { context: 'my rallies' }, icon: "fa-search", label: "My Rallies" });
      options.push({ onClick: "openSearch", params: { context: 'all rallies' }, icon: "fa-search", label: "Search" });
      options.push({ onClick: "newRally", params: { }, icon: "fa-plus-circle", label: "Create Rally" });
      options.push({ onClick: "refreshAll", params: { }, icon: "fa-sync", label: "Refresh" });
      return options;
    },
    /*filteredRallies() {
      return this.allRallies.filter(function(rally) {
        return rally.rally.attributes['child-count'] == 0
      });
    }*/
  },
  created() {
    if (this.myRallies && this.myRallies.length > 1) {
      this.isDashboardLoaded = true
    } else {
      console.log("fetching all the rallies")
      this.fetchDashboardRallies()
    }
    this.clearRallySearchResults();
  },

  methods: {
    ...mapActions("rallies", ["clearRallyState", "fetchMyRalliesWithInfo", "clearRallySearchResults"]),
    ...mapActions("user", ["registerUserDevice", "fetchUsersById"]),
    ...mapActions("memberships", ["fetchMembersByRally", "changeSort"]),
    membershipSort(rs){
      return rs.sort((a, b) => {
          if ( a?.rally?.attributes && a?.membership?.attributes && b?.rally?.attributes && b?.membership?.attributes){
            let sa = this.tempSortOrder[a.membership.id] != null ? this.tempSortOrder[a.membership.id] : a.membership.attributes['sort-order']
            let sb = this.tempSortOrder[b.membership.id] != null ? this.tempSortOrder[b.membership.id] : b.membership.attributes['sort-order']
            if (sa ==  null){
              sa = 10000
            }
            if (sb == null){
              sb = 10000
            }
            if (a.rally.attributes.status == 'Archived'){
              sa = 10001
            }
            try{
              if (b.rally.attributes.status == 'Archived'){
                sb = 10001
              }
            } catch (e){
              console.log(e)
            }
            if (sa < sb){
              return -1
            } else if (sa > sb) {
              return 1
            }
            return 0;
          } else {
            console.warn("Missing rally attributes:", a); 
            return 0;
          }
        });
      },
    onDragStart(evt) {
      this.drag = true; // Set drag state to true when dragging starts
      console.log("Drag started:", evt);
    },
    async onDragEnd(evt) {
      this.drag = false; // Reset drag state
      console.log("Drag ended:", evt);

      // Example: Handle moved item
      const movedItem = this.rallyListing[evt.oldIndex];
      const replacedItem = this.rallyListing[evt.newIndex];
      await this.handleItemMoved(evt.oldIndex, evt.newIndex, movedItem, replacedItem);
    },
    async handleItemMoved(oldIndex, newIndex, movedItem, replacedItem) {
      eventBus.$emit('overlayOn')
      this.tempSortOrder = {}
      this.tempSortOrder[movedItem.membership.id] =  newIndex 
      this.tempSortOrder[replacedItem.membership.id] =  newIndex + 0.1
      let rs = [...this.myRallies];
      let sortedMembershipIds = this.membershipSort(rs).map(obj => obj.membership.id)
      console.log("moving items")
      console.log(sortedMembershipIds)
      await this.changeSort({membershipIdOrdering: this.membershipSort(rs)})
      eventBus.$emit('overlayOff')
    },

    reloadPage(){
      location.reload()
    },
    async fetchDashboardRallies() {
      // TODO - clear out the rally state whenever this page is loaded due to the fact that
      // rallies that the user may not be an explicit member of may be loaded into the state
      // as the user clicks on the rabbit-trail links to Hubs
      // Eventually we should keep the state of the rallies that the user is a member of in a separate state
      //await this.clearRallyState()
      console.log("FETCH DASHBOARD RALLIES")
      await this.fetchMyRalliesWithInfo({});    
      this.isDashboardLoaded = true
    },
    getContentById(id) {
      return this.contentById[parseInt(id)]
        ? this.contentById[parseInt(id)]
        : this.contentById[-1];
    },

    getContentAttribute(id, attname) {
      var c = this.getContentById(id);
      if (c && c.attributes && c.attributes[attname]) {
        return c.attributes[attname];
      } else {
        return "";
      }
    },

    /*infiniteHandler2($state) {
      if (this.page < 20 && !this.parentRallyId) {
        //keep this call from running away!
        console.log(this.page);
        this.page += 1;
       this.fetchRalliesWithInfo();
      }
    },*/
    //we have to pass in the status filter for subsequent pages so we get a correct total count...by default the status is filtered anyhow but...
    //there seems to be a bug with the backend total count
    loadNextPage(){
      this.fetchMyRalliesWithInfo({});
    },
    infiniteHandler($state) {
      if (this.prevLoadCount == 0 && this.rallyListing){
        this.prevLoadCount = this?.rallyListing.length
      } 
      if (this.rallyListing && this.prevLoadCount == this?.rallyListing.length ){
        this.prevLoadCountSameCount++
      } else {
        this.prevLoadCountSameCount = 0
      }
      if (this.prevLoadCountSameCount <  4) {
        this.prevLoadCount = this?.rallyListing.length
        this.loadNextPage()
      }
    },
    log(message) {
      this.$log.debug(message);
    },
    newRally() {
      router.push('/start');
    },
    perform(method, params) {
      params ? this[method](params) : this[method]();
    },
  }
};
</script>
<style scoped>
.refresh-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* or any specific height you need */
    text-align: center;
    font-family: "Lato", sans-serif;
}
.refresh-font {
  font-family: "Lato", sans-serif;
}

</style>
